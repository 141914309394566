<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-md-3">
                    <router-link to="./welcomePage">
                        <card title="Email" class="img-email">
                            <img src="../../public/img/email1.png" >
                        </card>
                    </router-link>
                </div>
                 <div class="col-md-3">
                    <router-link to="./blogDetails">
                      <card title="Email" class="img-email">
                            <img src="../../public/img/email2.png" >
                      </card>
                    </router-link>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import ProjectsTable from './Tables/ProjectsTable'
  export default {
    name: 'tables',
    components: {
      ProjectsTable
    }
  };
</script>
<style>
.bg-gradient-success{
    background: linear-gradient(87deg, #DC0746 0, #55031c 100%) !important;
}
.gradient-success{
    background: linear-gradient(87deg, #DC0746 0, #DC0746 100%) !important;
}
.img-email{
    width: 100%;
    height: 300px;
    overflow: hidden;
}
.img-email:hover{
    height: 350px;
}
.img-email img{
    width: 100%;
    height: 100%;
}
</style>
