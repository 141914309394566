<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <!-- <div class="col-md-2 text-right btn-table-blog">
          <input
            @input="filterDataArray('filterAll')"
            v-model="model.FilterAll"
            type="text"
            placeholder="Recherche"
            class="form-control form-control-alternative"
          />
        </div> -->
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="sortedData"
      >
        <template slot="columns">
          <th>Nom</th>
          <th>prix</th>
          <th>Adresse</th>
          <th>Telephone</th>
          <th>Produits</th>
          <th>Action</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm"
                  >{{ row.owner.ownerId.firstName }} {{ row.owner.ownerId.lastName }}</span
                >
              </div>
            </div>
          </th>
          <td>
            <p class="Description-p">{{ row.payment }}</p>
          </td>
          <td>
            <p class="Description-p">
              {{ row.address }}
            </p>
          </td>
          <td>
            <p class="Description-p">{{ row.owner.phoneNumber }}</p>
          </td>
          <td>
            <p class="" v-html="row.formattedProducts" style="height:150px;overflow:auto"></p>
          </td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>
              <template>
                <!-- <a
                  class="dropdown-item"
                  v-bind:id="row._id"
                  href="#"
                  @click="(modals.modal3 = true), showDataElement(row)"
                  >Consulter commande</a
                > -->
                <a
                  class="dropdown-item"
                  href="#"
                  v-bind:id="row._id"
                  @click="refuseCommand(row)"
                  >Refuser</a
                >
                <a
                  class="dropdown-item"
                  v-bind:id="row._id"
                  href="#"
                  @click="acceptCommand(row)"
                  >Accepter</a
                >
              </template>
            </base-dropdown>
          </td>
    
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <ul class="pagination">
        <li class="page-item prev-page" @click="prevPage">
          <a aria-label="Previous" class="page-link">
            <span aria-hidden="true"
              ><i aria-hidden="true" class="fa fa-angle-left"></i></span
          ></a>
        </li>
        <li class="page-item next-page" @click="nextPage">
          <a aria-label="Next" class="page-link"
            ><span aria-hidden="true"
              ><i aria-hidden="true" class="fa fa-angle-right"></i></span
          ></a>
        </li>
        <li></li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import { CldContext, CldImage } from "cloudinary-vue";

export default {
  name: "tableCommandes",
  components: {
    Multiselect,
    CldContext,
    CldImage,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    commands: {
      type: Array,
    },
  },
  data() {
    return {
      typeModel: 0,
      urlImage:
        "https://res.cloudinary.com/dpy824jnw/image/upload/v1603987633/",
      url: "",
      publicId: "",
      modals: {
        modal1: false,
        modal2: false,
        modal3: false,
      },
      pageSize: 5,
      currentPage: 1,
      modelUpdate: {},
      model: {
        FilterAll: "",
        FilterCategorie: "",
        label: "",
        category: "",
        description: "",
        quantity: "",
        pictures: [],
        dimension: {
          width: [],
          height: [],
        },
        labelUpdate: "",
        categoryUpdate: "",
        descriptionUpdate: "",
        quantityUpdate: "",
        idUpdate: "",
      },
      selectedFilters: [],
      tableData: [],
      dataToFilter: [],
      sortedData: [],
      options: [],
    };
  },
  created() {
    axios
      .get("https://giftstore-blog.herokuapp.com/api/commande/")
      .then((response) => {
        console.log("commande", response);
        this.tableData = response.data.fetchedcommandes;
        this.dataToFilter = this.tableData;
        this.sortedElement();
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },
  methods: {
    sortedElement() {
      this.sortedData = this.commands.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return row;
      });
    },

    filterDataArray(MethodNameFilter) {
      var dataFilterData = this.tableData;
      if (this.selectedFilters.includes(MethodNameFilter)) {
        this.selectedFilters.splice(
          this.selectedFilters.indexOf(MethodNameFilter),
          1
        );
      }
      this.selectedFilters.push(MethodNameFilter);
      console.log(this.selectedFilters);
      this.selectedFilters.forEach((element) => {
        if (element === "filterAll") {
          dataFilterData = this.filterAll(dataFilterData);
        }
      });
      // this.dataToFilter = this.sortedData;
      this.sortedElement();
    },
    filterAll: function (dataFilterData) {
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.model.FilterAll === "") {
          return element;
        } else {
          return this.model.FilterAll.toLowerCase()
            .split(" ")
            .every((v) => element.label.toLowerCase().includes(v));
        }
      });
      return this.dataToFilter;
    },

    showDataElement(product) {
      this.modelUpdate = product;
      if ((this.modelUpdate.typeArticle = "autre")) {
        this.typeModel = 1;
      } else if ((this.modelUpdate.typeArticle = "chaussure")) {
        this.typeModel = 2;
      } else if ((this.modelUpdate.typeArticle = "bijoux")) {
        this.typeModel = 3;
      } else if ((this.modelUpdate.typeArticle = "tapis")) {
        this.typeModel = 4;
      } else if ((this.modelUpdate.typeArticle = "vetements")) {
        this.typeModel = 5;
      }
      console.log(this.modelUpdate);
    },
    nextPage: function () {
      if (this.currentPage * this.pageSize < this.commands.length) {
        this.currentPage++;
      }
      this.sortedElement();
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--;
      this.sortedElement();
    },
    adresse(adresse) {
      return (
        adresse.city +
        " " +
        adresse.exactAddress +
        " " +
        adresse.region +
        " " +
        adresse.postalCode
      );
    },
    async refuseCommand(item) {
      item.state = 2;
      await axios.put("https://giftstore-blog.herokuapp.com/api/commande/update-status", item)
      .then((response) => {
          this.$notifications.notify({
            message: "Commande refusée",
            type: "success",
          });
      })
      .catch((error) => {

      });
    },
    async acceptCommand(item) {
      item.state = 1;
      await axios.put("https://giftstore-blog.herokuapp.com/api/commande/update-status", item)
      .then((response) => {
        this.$notifications.notify({
            message: "Commande Acceptée",
            type: "success",
          });
      })
      .catch((error) => {

      });
    },
  },
};
</script>
<style>
.btn-table-blog button {
  background: #dc0746 !important;
  color: #fff;
}
.btn-table-blog button:hover {
  background: #fff !important;
  color: #dc0746;
}
.modal-body img {
  width: 100% !important;
}
/* .Description-p {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.image-blog img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.btn-add-img button {
  background: #dc0746;
  color: #fff;
}
.btn-add-img button:hover {
  background: #fff;
  color: #dc0746;
}
.update-image-section {
  margin-bottom: 3%;
}
.updata-image {
  padding: 2%;
  height: 150px;
  overflow: hidden;
}
.updata-image img {
  height: 100%;
}
.delete-imageToUpdate {
  color: #dc0746;
  cursor: pointer;
}
</style>
