<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="bestArticles"
      >
        <template slot="columns">
          <th>Événement</th>
          <th>Description</th>
          <th>Image</th>
          <th>Activer Événement</th>
          <th>Supprimer</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.category }}</span>
              </div>
            </div>
          </th>
          <td>
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.description }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="avatar-group">
              <a
                :href="urlImage + row.image"
                target="_blank"
                class="event-img"
                data-toggle="tooltip"
                data-original-title="product-name"
              >
                <!-- <img alt="Image placeholder" src="img/theme/team-1-800x800.jpg"> -->
                <cld-context cloudName="dpy824jnw">
                  <cld-image :publicId="row.image" crop="scale" />
                </cld-context>
              </a>
            </div>
          </td>
          <td>
            <div class="media align-items-center" v-if="row.state==true">
             <base-button
                    href="#"
                    class="btn btn-success"
                    @click="activeEvent(row._id)"
                    >Desactivé</base-button
                  >
            </div>
            <div class="media align-items-center" v-if="row.state==false">
             <base-button
                    href="#"
                    class="btn btn-success"
                                        :id="row._id"
                    @click="activeEvent(row._id)"
                    >Activer</base-button
                  >
            </div>
          </td>
           <td>
            <div class="media align-items-center">
             <base-button
                    href="#"
                    class="btn btn-primary"
                    @click="deleteEvent(row._id)"
                    >Supprimer</base-button
                  >
            </div>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";
import { VueEditor, Quill } from "vue2-editor";

export default {
  name: "tableBestArticles",
  components: {
    CldContext,
    CldImage,
    VueEditor,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      urlImage:
        "https://res.cloudinary.com/dpy824jnw/image/upload/v1603987633/",
      url: "",
      publicId: "",
      bestArticles: [],
    };
  },
  created() {
    axios
      .post("https://giftstore-blog.herokuapp.com/api/bestSelling/getAll")
      .then((response) => {
        console.log("articles", response);
        this.bestArticles = response.data.bestSelling;
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },

  methods: {
    deleteEvent(eventId){
      axios
      .post("https://giftstore-blog.herokuapp.com/api/bestSelling/delete",{idEvent:eventId})
      .then((response) => {
        console.log("articles", response);
           this.$notifications.notify({
                message: "Événement bien supprimé.",
                type: 'success'
              })
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    activeEvent(eventId){
      console.log(eventId)
      axios
      .post("https://giftstore-blog.herokuapp.com/api/bestSelling/activate",{eventId:eventId})
      .then((response) => {
        console.log("articles", response);
           this.$notifications.notify({
                message: "Evenement bien activé.",
                type: 'success'
              })
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    }
  },
  //  activeEvent(id){
  //     axios
  //     .post("https://giftstore-blog.herokuapp.com/api/bestSelling/update")
  //     .then((response) => {
  //       console.log("articles", response);
  //       this.bestArticles = response.data.bestSelling;
  //     })
  //     .catch((error) => {
  //       this.errorMessage = error.message;
  //       console.error("There was an error!", error);
  //     });
  //   }
  
};
</script>
<style>
.btn-success{
      background-color: #2dce89!important;
    border-color: #2dce89!important;
}
.event-img img {
  height: 75px;
  width: 150px;
}
.btn-table-blog button {
  background: #dc0746 !important;
  color: #fff;
}
.btn-table-blog button:hover {
  background: #fff !important;
  color: #dc0746;
}
.modal-body img {
  width: 100% !important;
}
.Description-p {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-blog img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.btn-add-img button {
  background: #dc0746;
  color: #fff;
}
.btn-add-img button:hover {
  background: #fff;
  color: #dc0746;
}
.update-image-section {
  margin-bottom: 3%;
}
.updata-image {
  padding: 2%;
  height: 150px;
  overflow: hidden;
}
.updata-image img {
  height: 100%;
}
.delete-imageToUpdate {
  color: #dc0746;
  cursor: pointer;
}
</style>
