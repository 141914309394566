<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0 email-screen">
          <img src="../../public/img/email1.png" />
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Email</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button href="#!" class="btn btn-primary"
                  @click="sendEmail()"
                    >Envoyer</base-button
                  >
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">
                  Information sur l'email
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Nom et Prénom"
                        placeholder="Nom et Prénom"
                        input-classes="form-control-alternative"
                        v-model="username"
                      />
                    </div>
                    <div class="col-lg-6">
                      <base-input
                        alternative=""
                        label="Email"
                        placeholder="Email"
                        input-classes="form-control-alternative"
                        v-model="email"
                      />
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <!-- Address -->
                <h6 class="heading-small text-muted mb-4">
                  Information sur l'article
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <base-input
                        alternative=""
                        label="Nom du blog"
                        placeholder="Nom du blog"
                        input-classes="form-control-alternative"
                        v-model="name1"
                      />
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <base-input
                        alternative=""
                        label="Lien du blog"
                        placeholder="Lien du blog"
                        input-classes="form-control-alternative"
                        v-model="link1"
                      />
                    </div>
                    <div class="col-md-12 col-md-12">
                      <div class="form-group">
                        <base-input alternative="" label="Détail blog">
                          <textarea
                            rows="4"
                            class="form-control form-control-alternative"
                            placeholder="Détail blog ..."
                            v-model="details1"
                          ></textarea>
                        </base-input>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <base-button href="#!" class="btn btn-primary" @click="openUploadModal()"
                        >Image</base-button
                      >
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <base-input
                        alternative=""
                        label="Nom du blog"
                        placeholder="Nom du blog"
                        input-classes="form-control-alternative"
                        v-model="name2"
                      />
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <base-input
                        alternative=""
                        label="Lien du blog"
                        placeholder="Lien du blog"
                        input-classes="form-control-alternative"
                        v-model="link2"
                      />
                    </div>
                    <div class="col-md-12 col-md-12">
                      <div class="form-group">
                        <base-input alternative="" label="Détail blog">
                          <textarea
                            rows="4"
                            class="form-control form-control-alternative"
                            placeholder="A few words about you ..."
   v-model="details2"></textarea
                          >
                        </base-input>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <base-button href="#!" class="btn btn-primary" @click="openUploadModal()"
                        >Image</base-button
                      >
                    </div>
                  </div>
                </div>
                <hr class="my-4" />
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <base-input
                        alternative=""
                        label="Nom du blog"
                        placeholder="Nom du blog"
                        input-classes="form-control-alternative"
                        v-model="name3"
                      />
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <base-input
                        alternative=""
                        label="Lien du blog"
                        placeholder="Lien du blog"
                        input-classes="form-control-alternative"
                        v-model="link3"
                      />
                    </div>
                    <div class="col-md-12 col-md-12">
                      <div class="form-group">
                        <base-input alternative="" label="Détail blog">
                          <textarea
                            rows="4"
                            class="form-control form-control-alternative"
                            placeholder="A few words about you ..."
   v-model="details3"></textarea
                          >
                        </base-input>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <base-button href="#!" class="btn btn-primary" @click="openUploadModal()"
                        >Image</base-button
                      >
                    </div>
                  </div>
                </div>
                <!-- Description -->
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";
export default {
  name: "emailWelcome",
    components: {
    CldContext,
    CldImage,},
  data() {
    return {
        username: "",
        email: "",
        name1: "",
        link1: "",
        details1: "",
        image: [],
        name2: "",
        link2: "",
        details2: "",
        name3: "",
        link3: "",
        details3: "",
    };
  },
    methods: {
    openUploadModal() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpy824jnw", upload_preset: "vlgevino" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info.public_id);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.image.push(this.publicId);
              console.log("test",this.model.pictures)
            }
          }
        )
        .open();
    },
    sendEmail() {
      if(this.name3==""){
         var emailToSend = {
        username: this.username,
        email: this.email,
        image: this.image,
        body: [{
                name: this.name1,
                link: this.link1,
                detail: this.details1,
              },
              {
                name: this.name2,
                link: this.link2,
                detail: this.details2,
              }
             ]
        // publishedBy: "5f89bdd27a8a5f7751b4188f",
      };
      }else{
        var emailToSend = {
        username: this.username,
        email: this.email,
        image: this.image,
        body: [{
                name: this.name1,
                link: this.link1,
                detail: this.details1,
              },
              {
                name: this.name2,
                link: this.link2,
                detail: this.details2,
              },
              {
                name: this.name3,
                link: this.link3,
                detail: this.details3,
              }]
        // publishedBy: "5f89bdd27a8a5f7751b4188f",
      };
      }
        console.log(emailToSend)
      axios
        .post("https://giftstore-blog.herokuapp.com/api/user/subscribeMail",emailToSend)
        .then((response) => {
          console.log("test123", response);
            this.$notifications.notify({
            message: "Email bien envoyé",
            type: "success",
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.$notifications.notify({
            message: "Erreur lors de l'envoye.",
            type: "danger",
          });
        });
    },
    }
};
</script>
<style>
.bg-gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #55031c 100%) !important;
}
.gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #dc0746 100%) !important;
}
.email-screen img {
  width: 100%;
}
.btn-add-user {
  margin-top: 2rem;
}
</style>
