<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right btn-table-blog">
          <base-button type="" size="sm" @click="modals.modal2 = true"
            >Ajouter un blog</base-button
          >
        </div>
        <modal :show.sync="modals.modal2">
          <h2 slot="header" class="modal-title" id="modal-title-default">
            Ajouter un blog
          </h2>
          <div class="row">
            <div class="col-lg-12">
              <base-input
                alternative=""
                label="Nom du blog"
                placeholder="Nom du blog"
                input-classes="form-control-alternative"
                v-model="model.nom"
              />
            </div>

          </div>
          <div class="row">
            <div class="col-lg-12">
              <base-input alternative="" label="Description brève ">
                <textarea
                  rows="4"
                  class="form-control form-control-alternative"
                  placeholder="Description bref ..."
                  v-model="model.DescriptionBref"
                ></textarea>
              </base-input>
            </div>
            <div class="col-lg-12">
              <base-input alternative="" label="Description détaillée">
                <vue-editor id="editor" useCustomImageHandler @image-added="handleImageAdded" v-model="model.DescriptionDet" class="ql-editor" :editorToolbar="defaultToolbar"></vue-editor>
                <h5>Live Preview</h5>
                <div v-html="model.DescriptionDet" class="ql-editor"></div>
                <!--                <textarea-->
<!--                  rows="4"-->
<!--                  class="form-control form-control-alternative"-->
<!--                  placeholder="Description detaillée ..."-->
<!--                  v-model="model.DescriptionDet"-->
<!--                ></textarea>-->
              </base-input>
            </div>
               <div class="col-lg-12 btn-add-img">
              <!-- <base-input
                alternative=""
                label="Country"
                placeholder="Country"
                input-classes="form-control-alternative"
                v-model="model.image"
              /> -->
              <base-button type="" size="sm" @click="openUploadModal">Upload files</base-button>
            </div>
            <div class="col-lg-12 text-right btn-table-blog">
              <base-button type="" size="sm" @click="addBlog"
                >Ajouter</base-button
              >
            </div>
          </div>
        </modal>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="sortedElement"
      >
        <template slot="columns">
          <th>Nom</th>
          <th>Image</th>
          <th>Description bref</th>
          <th>Description detaillé</th>
          <th>Date</th>
          <th>Action</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <!-- <a href="#" class="avatar rounded-circle mr-3">
                <img alt="Image placeholder" :src="row.img">
              </a> -->
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.title }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            <a
              href="#"
              class="image-blog"
              @click="modals.modal1 = true,showDataElement(row._id)"
            >
              <cld-context cloudName="dpy824jnw">
                <div>
                  <cld-image
                    :publicId="row.imgLink"
                  />
                </div>
              </cld-context>
              <!-- <img alt="Image placeholder" :src="row.imgLink" /> -->
            </a>
          </td>

          <td>
            <p class="Description-p">{{ row.briefDescription }}</p>
          </td>
          <td>
            <p class="Description-p">{{ row.detailedDescription }}</p>
          </td>

          <td class="date-data">
            {{ dateFormat(row.createdAt) }}
            <!-- <base-input
              class="input-group-alternative mb-3"
              placeholder="jj/mm/aaaa"
              addon-left-icon="ni ni-watch-time"
              :v-model="dateFormat(row.createdAt)"
            >
            </base-input> -->
          </td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>
              <template>
                <a
                  class="dropdown-item"
                  href="#"
                  v-bind:id="row._id"
                  @click="deleteBlog(row._id)"
                  >Supprimer</a
                >
                <a
                  class="dropdown-item"
                  v-bind:id="row._id"
                  href="#"
                  @click="modals.modal3 = true,showDataElement(row._id)"
                  >Modifier</a
                >
              </template>
            </base-dropdown>
          </td>
       <modal :show.sync="modals.modal3">
            <h2 slot="header" class="modal-title" id="modal-title-default">
              Modifier le blog
            </h2>
            <div class="row">
              <div class="col-lg-12">
                <base-input
                  alternative=""
                  label="Nom du blog"
                  placeholder="Nom du blog"
                  input-classes="form-control-alternative"
                  v-model="model.nomUpdate"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <base-input alternative="" label="Description bref">
                  <textarea
                    rows="4"
                    class="form-control form-control-alternative"
                    placeholder="Description bref ..."
                    v-model="model.DescriptionBrefUpdate"
                  ></textarea>
                </base-input>
              </div>
              <div class="col-lg-12">
                <!-- <base-input alternative="" label="Description detaillée">
                  <textarea
                    rows="4"
                    class="form-control form-control-alternative"
                    placeholder="Description detaillée ..."
                    v-model="model.DescriptionDetUpdate"
                  ></textarea>
                </base-input> -->
                  <base-input alternative="" label="Description détaillée">
                  <vue-editor v-model="model.DescriptionDetUpdate" @click="check()" class="ql-editor" :editorToolbar="defaultToolbar"></vue-editor>
                  <h5>Live Preview</h5>
                  <div v-html="model.DescriptionDetUpdate" class="ql-editor"></div>
              </base-input>
              </div>
               <div class="col-lg-12 btn-add-img">
              <base-button type="" size="sm" @click="openUploadModal">Upload files</base-button>
              <input type="hidden"   v-model="model.idUpdate">
              <input type="hidden"   v-model="model.image">

            </div>
              <div class="col-lg-12 text-right btn-table-blog">
                <base-button type="" size="sm" @click="updateBlog()"
                  >Modifier</base-button
                >
              </div>
            </div>
          </modal>
            <modal :show.sync="modals.modal1">
            <!-- <img alt="Image placeholder modal-img" :src="row.imgLink" /> -->
            <cld-context cloudName="dpy824jnw">
              <div style="display: flex; justify-content: center;">
                <cld-image :publicId="model.image" crop="scale" />
              </div>
            </cld-context>
          </modal>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <ul class="pagination">
        <li class="page-item prev-page" @click="prevPage">
          <a aria-label="Previous" class="page-link">
            <span aria-hidden="true"
              ><i aria-hidden="true" class="fa fa-angle-left"></i></span
          ></a>
        </li>
        <li class="page-item next-page" @click="nextPage">
          <a aria-label="Next" class="page-link"
            ><span aria-hidden="true"
              ><i aria-hidden="true" class="fa fa-angle-right"></i></span
          ></a>
        </li>
        <li>
        </li>
      </ul>


    </div>
  </div>
</template>
<script>
import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";
import { VueEditor, Quill } from "vue2-editor";


export default {
  name: "projects-table",
  components: {
    CldContext,
    CldImage,
    VueEditor
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      defaultToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image", "video"],
        ["clean"] // remove formatting button
      ],
      content: "<h1>Some initial content</h1>",
      url: "",
      publicId: "",
      modals: {
        modal1: false,
        modal2: false,
        modal3: false,
      },
      pageSize: 5,
      currentPage: 1,
      image:"",
      model: {
        nom: "",
        image: "",
        DescriptionBref: "",
        DescriptionDet: "",
        nomUpdate: "",
        imageUpdate: "",
        DescriptionBrefUpdate: "",
        DescriptionDetUpdate: "",
        idUpdate: "",
      },
      tableData: [],
    };
  },
  created() {
    axios
      .get("https://giftstore-blog.herokuapp.com/api/article/")
      .then((response) => {
        this.tableData = response.data.articles;
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },
  computed: {
    sortedElement: function() {
      return this.tableData.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return true;
      });
    },
  },
  mounted() {
    setTimeout(()=> {
      console.log(this)
    },10000)
  },
  methods: {
    handleImageAdded( Editor, cursorLocation) {
      window.cloudinary
              .openUploadWidget(
                { cloud_name: "dpy824jnw", upload_preset: "vlgevino" },
                (error, result) => {
                  if (!error && result && result.event === "success") {
                    console.log("Done uploading..: ", result);
                    this.url = result.info.url;
                    this.publicId = result.info.public_id;
                    this.model.image = this.publicId;
                    let url = result.info.url
                    Editor.insertEmbed(cursorLocation, "image", url);
                  }
                }
              )
              .open();
      console.log({
        file,
        Editor,
        cursorLocation,
        resetUploader
      })
    },
    showDataElement(id){
      axios
        .get("https://giftstore-blog.herokuapp.com/api/article/"+id)
        .then((response) => {
          console.log(response)
          this.model.nomUpdate =response.data.articles.title
          this.model.image = response.data.articles.imgLink
          this.model.DescriptionBrefUpdate = response.data.articles.briefDescription
          this.model.DescriptionDetUpdate =response.data.articles.detailedDescription
          this.model.idUpdate = id
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.$notifications.notify({
            message: "Erreur lors de l'affichage.",
            type: 'danger'
          })
        });
    },
    openUploadModal() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpy824jnw", upload_preset: "vlgevino" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.model.image = this.publicId;
            }
          }
        )
        .open();
    },
    updateBlog: function() {
      const article = {
        id: this.model.idUpdate,
        title: this.model.nomUpdate,
        briefDescription: this.model.DescriptionBrefUpdate,
        detailedDescription: this.model.DescriptionDetUpdate,
        imgLink: this.model.image,
        publishedBy: "5f89bdd27a8a5f7751b4188f",
      };
      axios
        .post("https://giftstore-blog.herokuapp.com/api/article/updateArticle", article)
        .then((response) => {
          console.log(response)
          this.modals.modal3 = false
          this.tableData = response.data.articles;
          this.$notifications.notify({
            message: "Element bien modifier.,",
            type: 'success'
          })
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
           this.$notifications.notify({
            message:" Erreur lors de la modification.,",
            type: 'danger'
          })
        });
    },
    deleteBlog: function(id) {
      var isokay = confirm("vous voulez vraiment supprimer cette élément");
      if(isokay){
          const article = { articleId: id };
          axios
            .post("https://giftstore-blog.herokuapp.com/api/article/removeArticle", article)
            .then((response) => {
              console.log(response);
              this.tableData = response.data.articles;
              this.$notifications.notify({
                message: "Element bien supprimer.",
                type: 'success'
              })
            })
            .catch((error) => {
              this.errorMessage = error.message;
              console.error("There was an error!", error);
                 this.$notifications.notify({
                  message: "Erreur lors de la suppression.",
                  type: 'danger'
                })
            });
      }
    },
    dateFormat: function(date) {
      var dateFormat = new Date(date);
      var day = dateFormat.getDate();
      var month = dateFormat.getMonth()+1;
      var years = dateFormat.getFullYear();
      var dateResult = month + "/" + day + "/" + years;
      return dateResult;
    },
    nextPage: function() {
      if (this.currentPage * this.pageSize < this.tableData.length)
        this.currentPage++;
    },
    prevPage: function() {
      if (this.currentPage > 1) this.currentPage--;
    },
    addBlog: function() {
      const article = {
        title: this.model.nom,
        briefDescription: this.model.DescriptionBref,
        detailedDescription: this.model.DescriptionDet,
        imgLink: this.model.image,
        publishedBy: "5f89bdd27a8a5f7751b4188f",
      };
      console.log(this);
      axios
        .post("https://giftstore-blog.herokuapp.com/api/article/addArticle", article)
        .then((response) => {
          console.log(response);
          this.modals.modal2 = false
          this.model.DescriptionDet = ""
          this.model.DescriptionBref = ""
          this.model.nom = ""
          this.tableData = response.data.articles
          this.$notifications.notify({
              message: "Element bien ajouter.",
              type: 'success'
          })
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
             this.$notifications.notify({
            message: "Erreur lors de l'ajout'.",
            type: 'danger'
          })
        });
    },
  },
};
</script>
<style>
.btn-table-blog button {
  background: #dc0746 !important;
  color: #fff;
}
.btn-table-blog button:hover {
  background: #fff !important;
  color: #dc0746;
}
.modal-body img {
  width: 100% !important;
}
.Description-p {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-blog img{
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.btn-add-img button{
  background: #dc0746;
  color: #fff;
}
.btn-add-img button:hover{
  background: #fff;
  color: #dc0746;
}
</style>
