<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
          <div class="col-md-4 text-right btn-table-blog">
          <input
            @input="filterDataArray('filterAll')"
            v-model="model.FilterAll"
            type="text"
            placeholder="Recherche"
            class="form-control form-control-alternative"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="sortedData"
      >
        <template slot="columns">
          <th>Nom et prénom</th>
          <th>Email</th>
          <th>Numero</th>
          <th>Date de naissance</th>
          <th>Adresse</th>
          <!-- <th>Action</th> -->
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.firstName }} {{ row.lastName }}</span>
              </div>
            </div>
          </th>
          <td>
            <p class="Description-p">{{ row.email }}</p>
          </td>
           <td>
            <p class="Description-p">{{ row.category }}</p>
          </td>
           <td>
            <p class="Description-p">{{ dateOfBirth(row) }}</p>
          </td>
          <td>
            <p class="Description-p">{{ address(row) }}</p>
          </td>


        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <ul class="pagination">
        <li class="page-item prev-page" @click="prevPage">
          <a aria-label="Previous" class="page-link">
            <span aria-hidden="true"
              ><i aria-hidden="true" class="fa fa-angle-left"></i></span
          ></a>
        </li>
        <li class="page-item next-page" @click="nextPage">
          <a aria-label="Next" class="page-link"
            ><span aria-hidden="true"
              ><i aria-hidden="true" class="fa fa-angle-right"></i></span
          ></a>
        </li>
        <li></li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";
import { VueEditor, Quill } from "vue2-editor";
import Multiselect from "vue-multiselect";

export default {
  name: "tableUsers",
  components: {
    CldContext,
    CldImage,
    VueEditor,
    Multiselect,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
     
      pageSize: 5,
      currentPage: 1,

      model: {
        FilterAll: "",
      },
      selectedFilters: [],
      tableData: [],
      dataToFilter: [],
      sortedData: [],
      options: [],
    };
  },

  created() {
     var userId = {
        userId: window.localStorage.getItem("user"),
      };
    axios
      .post(
          "https://giftstore-blog.herokuapp.com/api/user/friend/getAllUsers",
          userId
        )
      .then((response) => {
        console.log("articles", response);
        this.tableData = response.data.users;
        this.dataToFilter = this.tableData;
        this.sortedElement();
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },
  computed: {
  },
  mounted() {
    setTimeout(() => {
      console.log(this);
    }, 10000);
  },
  methods: {
    address(row){
      console.log(row)
      if(row.address){
          return row.address.city + " " + row.address.exactAddress + " " + row.address.region + "," + row.address.postalCode
      }else{
        return "L'adresse n'existe pas"
      }
    },
    dateOfBirth(row){
        if(row.dateOfBirth){
            var dateUser = new Date(row.dateOfBirth);
            var day = dateUser.getDate();
            var month = dateUser.getMonth()+1;
            var years = dateUser.getFullYear();
            var date  = this.filterDate(day)+ "-" + this.filterDate(month) + "-" +  years
          return date
      }else{
        return "L'adresse n'existe pas"
      }
    },
      filterDate(valueToChange) {

      if (valueToChange >= 0 && valueToChange < 9) {
        valueToChange = "0" + valueToChange.toString();
      }
      return valueToChange;
    },
    sortedElement() {
      this.sortedData = this.dataToFilter.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return row;
      });
    },

    filterDataArray(MethodNameFilter) {
      var dataFilterData = this.tableData;
      if (this.selectedFilters.includes(MethodNameFilter)) {
        this.selectedFilters.splice(
          this.selectedFilters.indexOf(MethodNameFilter),
          1
        );
      }
      this.selectedFilters.push(MethodNameFilter);
      console.log(this.selectedFilters);
      this.selectedFilters.forEach((element) => {
        if (element === "filterAll") {
          dataFilterData = this.filterAll(dataFilterData);
        }
      });
      // this.dataToFilter = this.sortedData;
      this.sortedElement();
    },
    filterAll: function(dataFilterData) {
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.model.FilterAll === "") {
          return element;
        } else {
          var name=element.firstName+" "+ element.lastName
          return this.model.FilterAll.toLowerCase()
            .split(" ")
            .every((v) => name.toLowerCase().includes(v));
        }
      });
      return this.dataToFilter;
    },


    nextPage: function() {
      if (this.currentPage * this.pageSize < this.tableData.length) {
        this.currentPage++;
      }
      this.sortedElement();
    },
    prevPage: function() {
      if (this.currentPage > 1) this.currentPage--;
      this.sortedElement();
    },
  },
};
</script>
<style>
.btn-table-blog button {
  background: #dc0746 !important;
  color: #fff;
}
.btn-table-blog button:hover {
  background: #fff !important;
  color: #dc0746;
}
.modal-body img {
  width: 100% !important;
}
/* .Description-p {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.image-blog img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.btn-add-img button {
  background: #dc0746;
  color: #fff;
}
.btn-add-img button:hover {
  background: #fff;
  color: #dc0746;
}
.update-image-section {
  margin-bottom: 3%;
}
.updata-image {
  padding: 2%;
  height: 150px;
  overflow: hidden;
}
.updata-image img {
  height: 100%;
}
.delete-imageToUpdate {
  color: #dc0746;
  cursor: pointer;
}
</style>
