<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
            <div class="row">
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Nombre des Articles"
                                type="gradient-red"
                                sub-title="99"
                                icon="ni ni-active-40"
                                class="mb-4 mb-xl-0">
                    </stats-card>
                </div>
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Nombre des commentaires"
                                type="gradient-orange"
                                sub-title="99"
                                icon="ni ni-chart-pie-35"
                                class="mb-4 mb-xl-0">
                    </stats-card>
                </div>
                <div class="col-xl-4 col-lg-6">
                    <stats-card title="Nombre des reponses"
                                type="gradient-green"
                                sub-title="99"
                                icon="ni ni-money-coins"
                                class="mb-4 mb-xl-0">
                    </stats-card>

                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <projects-table title="Blog"></projects-table>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import ProjectsTable from './Tables/ProjectsTable'
  export default {
    name: 'tables',
    components: {
      ProjectsTable
    }
  };
</script>
<style>
.bg-gradient-success{
    background: linear-gradient(87deg, #DC0746 0, #55031c 100%) !important;
}
.gradient-success{
    background: linear-gradient(87deg, #DC0746 0, #DC0746 100%) !important;
}
</style>
