<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <div class="article-table">
            <tableUsers title="Utilisateur"></tableUsers>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import tableUsers from "./Tables/tableUsers";

export default {
  components: {
    tableUsers,
  },
  name: "users",
  data() {
    return {
      
    };
  },
  methods: {
   
  },
};
</script>
<style>
.bg-gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #55031c 100%) !important;
}
.gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #dc0746 100%) !important;
}

</style>
