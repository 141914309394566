<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
        </base-header>

       <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0 email-screen">
                    <img src="../../public/img/email2.png" >
                </div>

                <div class="col-xl-8 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Email</h3>
                                </div>
                                <div class="col-4 text-right">
                                     <base-button href="#!" class="btn btn-primary" @click="sendEmail()">Envoyer</base-button>
                                </div>
                            </div>
                        </div>
                        <template>
                            <form @submit.prevent>
                                <h6 class="heading-small text-muted mb-4">Information sur l'email </h6>
                                <div class="pl-lg-4">
                                    <div class="row" v-for="(element, index) in numberOfEmail" :key="index">
                                         <div class="col-lg-10 col-md-10">
                                            <base-input alternative=""
                                                        label="Email"
                                                        placeholder="Email"
                                                        input-classes="form-control-alternative"
                                                        v-model="email[index]"
                                            />
                                        </div>
                                         <div class="col-lg-1 col-md-1 btn-add-user">
                                            <base-button href="#!" class="btn btn-primary" @click="addInputEmail('add',index)">+</base-button>
                                        </div>
                                        <div class="col-lg-1 col-md-1 btn-add-user">
                                            <base-button href="#!" class="btn btn-primary" @click="addInputEmail('delete',index)">-</base-button>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                                <!-- Address -->
                                <h6 class="heading-small text-muted mb-4">Information sur l'article</h6>
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <base-input alternative=""
                                                        label="Nom du blog"
                                                        placeholder="Nom du blog"
                                                        input-classes="form-control-alternative"
                                                        v-model="name"
                                            />
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <base-input alternative=""
                                                        label="Lien du blog"
                                                        placeholder="Lien du blog"
                                                        input-classes="form-control-alternative"
                                                        v-model="link"
                                            />
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <base-input alternative=""
                                                        label="Objet du blog"
                                                        placeholder="Objet du blog"
                                                        input-classes="form-control-alternative"
                                                        v-model="objectBlog"
                                            />
                                        </div>
                                        <div class="col-md-12 col-md-12">
                                            <div class="form-group">
                                                <base-input alternative=""
                                                            label="Détail blog">
                                                    <textarea rows="4" class="form-control form-control-alternative" placeholder="A few words about you ..."
                                                     v-model="details"></textarea>
                                                </base-input>
                                            </div>
                                        </div>
                                        <div class="col-lg-4">
                                            <base-button href="#!" @click="openUploadModal()" class="btn btn-primary">Image</base-button>
                                        </div>
                                    </div>
                                </div>
                                <hr class="my-4" />
                              
                                <!-- Description -->
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";
  export default {
    name: 'emailBlog',
        components: {
    CldContext,
    CldImage,},
    data() {
      return {
        numberOfEmail:1,
        email: [],
        name: "",
        link: "",
        details: "",
        objectBlog:"",
        image: "",
      }
    },
    methods: {
    openUploadModal() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpy824jnw", upload_preset: "vlgevino" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info.public_id);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.image=this.publicId;
            }
          }
        )
        .open();
    },
    addInputEmail(type,index){
        console.log(index)
        if(type=="add"){
            this.numberOfEmail++
        }else if((type=="delete")&&(this.numberOfEmail>1)){
            this.numberOfEmail--;
            this.email.splice(
            index,
            1
                );
        }else{
           this.$notifications.notify({
            message: "Impossible de réaliser cette action.",
            type: "danger",
          }); 
        }
        console.log(this.email)
    },
      sendEmail() {
        const email = {
        email: this.email,
        title: this.name,
        buttonLink: this.link,
        detail: this.details,
        objectBlog:this.objectBlog,
        photoLink: this.image,
      };
      axios
        .post("https://giftstore-blog.herokuapp.com/api/article/newBlogMail",email)
        .then((response) => {
          console.log("test123", response);
            this.$notifications.notify({
            message: "Email bien envoyé",
            type: "success",
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.$notifications.notify({
            message: "Erreur lors de l'affichage.",
            type: "danger",
          });
        });
    },
      }
  };
</script>
<style>
.bg-gradient-success{
    background: linear-gradient(87deg, #DC0746 0, #55031c 100%) !important;
}
.gradient-success{
    background: linear-gradient(87deg, #DC0746 0, #DC0746 100%) !important;
}
.email-screen img{
    width:100%
}
.btn-add-user{
    margin-top: 2rem;
}
</style>