<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <form role="form">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Mot de passe"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>

            <!-- <base-checkbox class="custom-control-alternative">
                                <span class="text-muted">Remember me</span>
                            </base-checkbox> -->
            <div class="text-center">
              <base-button type="primary" class="my-4" @click="login">Connexion</base-button>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    login: function() {
      const article = {
        email: this.model.email,
        password: this.model.password,
      };
      axios
        .post("https://giftstore-blog.herokuapp.com/api/user/login", article)
        .then((response) => {
          console.log(response);
          localStorage.setItem("localStorageT",response.data.token );
          localStorage.setItem("user",response.data.user._id );
          localStorage.setItem('userInfo', response.data.user.firstName + " " +response.data.user.lastName)
          this.$router.push({ name: 'blog' })
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
    },
  },
};
</script>
<style></style>
