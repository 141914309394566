<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <div class="article-table">
            <tableCommandes title="Commandes en cours" :commands="dataToFilter.created"></tableCommandes>
          </div>
          <div class="article-table">
            <tableCommandes title="Commandes refusées" :commands="dataToFilter.refused"></tableCommandes>
          </div>
          <div class="article-table">
            <tableCommandes title="Commandes acceptées" :commands="dataToFilter.accepted"></tableCommandes>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";
import tableCommandes from "./Tables/tableCommandes";
import Multiselect from "vue-multiselect";

export default {
  components: {
    tableCommandes,
    CldContext,
    CldImage,
    Multiselect,
  },
  name: "tableCommande",
  created() {
    axios
      .get("https://giftstore-blog.herokuapp.com/api/commande/")
      .then((response) => {
        console.log("commande1", response);
        response.data.fetchedcommandes.map((command) => {
          // command.formattedProducts = command.product.map(product => {
          //   return `Nom du produit: ${product.label}, Prix: ${product.productPrice}<br>`
          // })[0]
          command.product.forEach(element => {
            console.log(element)
            var option="option: "
            var option1=""
            if(element.taille){
               option+=element.taille
            }else if(element.pointure){
               option+=element.pointure
            }else if(element.widthMax){
              option+=element.widthMax
               option1=element.heightMax
            }
            command.formattedProducts =`Nom du produit: ${element.label}, Prix: ${element.productPrice}<br>,${option},${option1}, quantité: ${element.quantite}, couleur: <img src="https://res.cloudinary.com/dpy824jnw/image/upload/v1604061374/${element.color}" style="width:25px;height:25px"> <br> <br>` + command.formattedProducts
          });
        })
        this.dataToFilter.accepted = response.data.fetchedcommandes.filter(com => com.state == 1);
        this.dataToFilter.refused = response.data.fetchedcommandes.filter(com => com.state == 2);
        this.dataToFilter.created = response.data.fetchedcommandes.filter(com => com.state == 0);
      })
      .catch((error) => {
        //this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },
  data() {
    return {
      numberOfArticle: 1,
      numberOfDetails: 1,
      color: [],
      picture: [],
      model: {
        label: "",
        category: [],
        description: "",
        quantity: [],
        picture: [],
        productPrice: 0,
        filtre: [],
        motCle: [],
        // dimension:[{
        //   width: [],
        //   height: [],
        //   },
        // ],
        widthMax: [],
        widthMin: [],
        heightMax: [],
        heightMin: [],

        colors: [],
        taille: [],
        pointure: [],
        disponibility: [],
        type: "",
        typeArticle: "",
      },
      options: [],
      classValue1: "elements-product col-md-12",
      classValue2: "elements-product col-md-12",
      classValue3: "elements-product col-md-12",
      classValue4: "elements-product col-md-12",
      dataToFilter: {
        refused: [],
        accepted: [],
        created: []
      }
    };
  },
  methods: {
    displayElement(arg) {
      this.typeArticle = arg;
      if (arg == "chaussure") {
        this.classValue1 = "chaussure col-md-12";
        this.classValue2 = "elements-product col-md-12";
        this.classValue3 = "elements-product col-md-12";
        this.classValue4 = "elements-product col-md-12";
      } else if (arg == "vetements") {
        this.classValue2 = "vetements col-md-12";
        this.classValue1 = "elements-product col-md-12";
        this.classValue3 = "elements-product col-md-12";
        this.classValue4 = "elements-product col-md-12";
      } else if (arg == "tapis") {
        this.classValue3 = "tapis col-md-12";
        this.classValue2 = "elements-product col-md-12";
        this.classValue1 = "elements-product col-md-12";
        this.classValue4 = "elements-product col-md-12";
      } else if (arg == "autre") {
        this.classValue4 = "autre col-md-12";
        this.classValue2 = "elements-product col-md-12";
        this.classValue3 = "elements-product col-md-12";
        this.classValue1 = "elements-product col-md-12";
      }
    },
    addInputArticle(type, index) {
      console.log(index);
      if (type == "add") {
        this.numberOfArticle++;
      } else if (type == "delete" && this.numberOfArticle > 1) {
        this.numberOfArticle--;
        this.email.splice(index, 1);
      } else {
        this.$notifications.notify({
          message: "Impossible de réaliser cette action.",
          type: "danger",
        });
      }
      console.log(this.email);
    },
    addInputDetail(type, index) {
      console.log(index);
      if (type == "add") {
        this.numberOfDetails++;
      } else if (type == "delete" && this.numberOfDetails > 1) {
        this.numberOfDetails--;
        this.email.splice(index, 1);
      } else {
        this.$notifications.notify({
          message: "Impossible de réaliser cette action.",
          type: "danger",
        });
      }
      console.log(this.email);
    },
    addCategory(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.model.category.push(tag);
    },
    addCategory(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.model.category.push(tag);
    },
    addQuantity(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.model.quantity.push(tag);
    },
    addTaille(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.model.taille.push(tag);
    },
    addFiltre(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.model.filtre.push(tag);
    },
    addPointure(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.model.pointure.push(tag);
    },
    addMotCle(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.model.motCle.push(tag);
    },

    openUploadModalColor() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpy824jnw", upload_preset: "vlgevino" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.color.push(this.publicId);
              console.log(this.model.colors);
            }
          }
        )
        .open();
      this.model.colors.push(this.color);
    },
    openUploadModal() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpy824jnw", upload_preset: "vlgevino" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.picture.push(this.publicId);
              console.log(this.model.pictures);
            }
          }
        )
        .open();
      this.model.pictures.push(this.picture);
    },
    AddArticle: function () {
      console.log("model", this.model);
      axios
        .post(
          "https://giftstore-blog.herokuapp.com/api/product/add",
          this.model
        )
        .then((response) => {
          console.log(response);
          this.$notifications.notify({
            message: "Element bien ajouté.",
            type: "success",
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.$notifications.notify({
            message: "Erreur lors de l'ajout'.",
            type: "danger",
          });
        });
    },
  },
};
</script>
<style>
.bg-gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #55031c 100%) !important;
}
.gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #dc0746 100%) !important;
}
.email-screen img {
  width: 100%;
}
.btn-add-user {
  margin-top: 2rem;
}
.article-table {
  margin-top: 5%;
}
.form-control-alternative .multiselect__content-wrapper .multiselect__content {
  background: #172b4d !important;
  cursor: pointer;
  width: 70%;
  z-index: 99999;
  border: none;
  color: #f7fafc;
  position: absolute;
}
.multiselect__input {
  margin-top: 20px;
  z-index: 99999;
  border: none;
}
.multiselect__tag {
  background: #172b4d;
  margin-right: 1%;
  padding: 1%;
  border-radius: 5px;
  color: #f7fafc;
}
.btn-color {
  width: 100%;
}
.mt-4 {
  margin-top: 3.8% !important;
}
.elements-product {
  display: none;
}
.chaussure {
  display: block !important;
}
.vetements {
  display: block !important;
}
.tapis {
  display: block !important;
}
.autre {
  display: block !important;
}
</style>
