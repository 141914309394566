<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Article Détail</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button
                    href="#!"
                    class="btn btn-primary"
                    @click="AddArticle()"
                    >enregistrer</base-button
                  >
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">
                  Information sur l'article
                </h6>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <base-input
                        alternative=""
                        label="Nom de l'article"
                        placeholder="Nom de l'article"
                        input-classes="form-control-alternative"
                        v-model="model.label"
                      />
                    </div>

                    <div class="col-lg-6 col-md-6 mt-3">
                      <label for="" class="form-control-label"
                        >Type commande</label
                      >
                      <br />
                      <div class="text-center">
                        Lancer commande
                        <input
                          v-model="model.orderType"
                          value="lancer commande"
                          type="radio"
                          name="typeCommande"
                          id=""
                          class="form-control-alternative"
                        />
                        pré-vente
                        <input
                          v-model="model.orderType"
                          value="pré-vente"
                          type="radio"
                          name="typeCommande"
                          id=""
                          class="form-control-alternative"
                        />
                        Commander
                        <input
                          v-model="model.orderType"
                          value="Commander"
                          type="radio"
                          name="typeCommande"
                          id=""
                          class="form-control-alternative"
                        />
                      </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-4">
                      <base-input
                        alternative=""
                        label="Quantité"
                        placeholder="Quantité"
                        input-classes="form-control-alternative"
                        v-model="model.quantity"
                      />
                    </div>
                    <div class="col-lg-4 col-md-4">
                      <base-input
                        alternative=""
                        label="Prix"
                        placeholder="Prix"
                        input-classes="form-control-alternative"
                        v-model="model.productPrice"
                      />
                    </div> -->
                    <!-- <div class="col-lg-6">
                      <label for="" class="form-control-label">Catégorie</label>
                      <select
                        name=""
                        id=""
                        class="form-control form-control-alternative"
                        v-model="model.category"
                      >
                        <option>Anniversaire-Homme</option>
                        <option>Anniversaire-Femme</option>
                        <option>Anniversaire-Enfant</option>
                        <option>Anniversary-Homme</option>
                        <option>Anniversary-Femme</option>
                        <option>Naissance-Maman</option>
                        <option>Naissance-Bebe</option>
                        <option>Naissance-Grand-frere-soeur</option>
                        <option>Cadeau-daffaire</option>
                        <option>Occasions-Cadeau-mariage</option>
                        <option>Occasions-Cadeau-Reussite </option>
                        <option>Occasions-Cadeau-Demenagement</option>
                        <option>Occasions-Cadeau-visite</option>
                      </select>
                    </div> -->
                    <div class="col-lg-4">
                      <label for="" class="form-control-label">Catégorie</label>
                      <base-input
                        alternative=""
                        placeholder="Catégorie"
                        input-classes="form-control-alternative"
                      >
                        <multiselect
                          @input="test()"
                          v-model="model.category"
                          tag-placeholder=" Catégorie"
                          placeholder=" Catégorie"
                          label="name"
                          track-by="code"
                          :options="optionsCategorie"
                          :multiple="true"
                          :taggable="true"
                          @tag="addCategory"
                          class="form-control form-control-alternative"
                        ></multiselect>
                      </base-input>
                      <!-- <pre class="language-json"><code>{{ value  }}</code></pre> -->
                    </div>

                    <div class="col-lg-4 col-md-4">
                      <label for="" class="form-control-label">Filter</label>
                      <base-input
                        alternative=""
                        placeholder="Filter"
                        input-classes="form-control-alternative"
                      >
                        <multiselect
                          v-model="model.filtre"
                          tag-placeholder=" Filter"
                          placeholder=" Filter"
                          label="name"
                          track-by="code"
                          :options="options"
                          :multiple="true"
                          :taggable="true"
                          @tag="addFiltre"
                          class="form-control form-control-alternative"
                        ></multiselect>
                      </base-input>
                      <!-- <pre class="language-json"><code>{{ value  }}</code></pre> -->
                    </div>
                    <div class="col-lg-4 col-md-4">
                      <label for="" class="form-control-label">Mot clé</label>
                      <base-input
                        alternative=""
                        placeholder="Mot clé"
                        input-classes="form-control-alternative"
                      >
                        <multiselect
                          v-model="model.motCle"
                          tag-placeholder="Mot clé"
                          placeholder="Mot clé"
                          label="name"
                          track-by="code"
                          :options="options"
                          :multiple="true"
                          :taggable="true"
                          @tag="addMotCle"
                          class="form-control form-control-alternative"
                        ></multiselect>
                      </base-input>
                      <!-- <pre class="language-json"><code>{{ value  }}</code></pre> -->
                    </div>
                    <div class="col-lg-6">
                      <label for="" class="form-control-label"
                        >Date de disponibilité (début)</label
                      >
                      <input
                        v-model="model.disponibility[0]"
                        type="date"
                        name=""
                        id=""
                        class="form-control form-control-alternative"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label for="" class="form-control-label"
                        >Date de disponibilité (fin)</label
                      >
                      <input
                        v-model="model.disponibility[1]"
                        type="date"
                        name=""
                        id=""
                        class="form-control form-control-alternative"
                      />
                    </div>
                    <div class="col-md-12 col-md-12 mt-3">
                      <div class="form-group">
                        <base-input alternative="" label="Détail de l'article">
                          <textarea
                            rows="4"
                            class="form-control form-control-alternative"
                            placeholder="Description"
                            v-model="model.description"
                          ></textarea>
                        </base-input>
                      </div>
                    </div>
                    <!-- <div class="col-lg-4">
                      <label for="" class="form-control-label">Taille</label>
                      <base-input
                        alternative=""
                        placeholder="Taille"
                        input-classes="form-control-alternative"
                      >
                        <multiselect
                          v-model="model.taille"
                          tag-placeholder=" Taille"
                          placeholder="Taille"
                          label="name"
                          track-by="code"
                          :options="options"
                          :multiple="true"
                          :taggable="true"
                          @tag="addTaille"
                          class="form-control form-control-alternative"
                        ></multiselect>
                      </base-input>
                    </div>
                    <div class="col-lg-4">
                      <label for="" class="form-control-label">Pointure</label>
                      <base-input
                        alternative=""
                        placeholder="Pointure"
                        input-classes="form-control-alternative"
                      >
                        <multiselect
                          v-model="model.pointure"
                          tag-placeholder=" Pointure"
                          placeholder=" Pointure"
                          label="name"
                          track-by="code"
                          :options="options"
                          :multiple="true"
                          :taggable="true"
                          @tag="addPointure"
                          class="form-control form-control-alternative"
                        ></multiselect>
                      </base-input>
                    </div> -->
                    <div class="col-md-3 mt-3">
                      <div class="form-group">
                        <button
                          class="btn btn btn-primary btn-default"
                          @click="displayElement('chaussure')"
                        >
                          chaussure
                        </button>
                      </div>
                    </div>
                    <div class="col-md-3 mt-3">
                      <div class="form-group">
                        <button
                          class="btn btn btn-primary btn-default"
                          @click="displayElement('vetements')"
                        >
                          Vêtements
                        </button>
                      </div>
                    </div>
                    <div class="col-md-2 mt-3">
                      <div class="form-group">
                        <button
                          class="btn btn btn-primary btn-default"
                          @click="displayElement('tapis')"
                        >
                          tapis
                        </button>
                      </div>
                    </div>
                    <div class="col-md-2 mt-3">
                      <div class="form-group">
                        <button
                          class="btn btn btn-primary btn-default"
                          @click="displayElement('bijoux')"
                        >
                          bijoux
                        </button>
                      </div>
                    </div>
                    <div class="col-md-2 mt-3">
                      <div class="form-group">
                        <button
                          class="btn btn btn-primary btn-default"
                          @click="displayElement('autre')"
                        >
                          Autre
                        </button>
                      </div>
                    </div>

                    <div :class="classValue1">
                      <div
                        class="row"
                        v-for="(element, index) in model.chaussure"
                        :key="index"
                      >
                        <div class="col-lg-12 col-md-12">
                          <base-input
                            alternative=""
                            label="Prix"
                            placeholder="Prix"
                            input-classes="form-control-alternative"
                            v-model="model.productPrice"
                          />
                        </div>
                        <div class="col-lg-1 col-md-1 mt-4">
                          <base-button
                            href="#!"
                            class="btn btn-primary"
                            @click="addInputArticle('add', 'chaussure', index)"
                            >+</base-button
                          >
                        </div>
                        <div class="col-lg-1 col-md-1 mt-4">
                          <base-button
                            href="#!"
                            class="btn btn-primary"
                            @click="
                              addInputArticle('delete', 'chaussure', index)
                            "
                            >-</base-button
                          >
                        </div>
                        <div class="col-lg-2 col-md-2 mt-3">
                          <label for="" class="form-control-label"
                            >Couleur</label
                          >
                          <br />
                          <base-button
                            href="#!"
                            class="btn btn-primary btn-color"
                            @click="openUploadModalColor(element)"
                            >Couleur</base-button
                          >
                        </div>
                        <div class="col-lg-2 col-md-2 mt-3">
                          <label for="" class="form-control-label">Image</label>
                          <br />
                          <base-button
                            href="#!"
                            class="btn btn-primary btn-color"
                            @click="openUploadModal(element.image)"
                            >Image</base-button
                          >
                        </div>

                        <div class="col-md-6">
                          <div
                            class="row"
                            v-for="(item, i) in element.pointureEtQuantites"
                            :key="i"
                          >
                            <div class="col-lg-6 col-md-6 mt-3">
                              <base-input
                                alternative=""
                                label="Pointure"
                                placeholder="Pointure"
                                input-classes="form-control-alternative"
                                v-model="item.pointure"
                              />
                            </div>
                            <div class="col-lg-6 col-md-6 mt-3">
                              <base-input
                                alternative=""
                                label="Quantité"
                                placeholder="Quantité"
                                input-classes="form-control-alternative"
                                v-model="item.quantite"
                              />
                            </div>

                            <div
                              class="col-lg-8 col-md-8 offset-md-4 offset-lg-4"
                            >
                              <div class="row">
                                <div class="col-lg-4 col-md-4">
                                  <base-button
                                    href="#!"
                                    class="btn btn-primary"
                                    @click="
                                      addInputDetail(
                                        'add',
                                        'chaussure',
                                        index,
                                        i
                                      )
                                    "
                                    >+</base-button
                                  >
                                </div>
                                <div class="col-lg-4 col-md-4">
                                  <base-button
                                    href="#!"
                                    class="btn btn-primary"
                                    @click="
                                      addInputDetail(
                                        'delete',
                                        'chaussure',
                                        index,
                                        i
                                      )
                                    "
                                    >-</base-button
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div :class="classValue2">
                      <div class="row">
                        <div class="col-lg-12 col-md-12">
                          <base-input
                            alternative=""
                            label="Prix"
                            placeholder="Prix"
                            input-classes="form-control-alternative"
                            v-model="model.productPrice"
                          />
                        </div>
                      </div>

                      <div
                        class="row"
                        v-for="(element, index) in model.vetements"
                        :key="index"
                      >
                        <div class="col-lg-1 col-md-1 mt-4">
                          <base-button
                            href="#!"
                            class="btn btn-primary"
                            @click="addInputArticle('add', 'vetements', index)"
                            >+</base-button
                          >
                        </div>
                        <div class="col-lg-1 col-md-1 mt-4">
                          <base-button
                            href="#!"
                            class="btn btn-primary"
                            @click="
                              addInputArticle('delete', 'vetements', index)
                            "
                            >-</base-button
                          >
                        </div>
                        <div class="col-lg-2 col-md-2 mt-3">
                          <label for="" class="form-control-label"
                            >Couleur</label
                          >
                          <br />
                          <base-button
                            href="#!"
                            class="btn btn-primary btn-color"
                            @click="openUploadModalColor(element)"
                            >Couleur</base-button
                          >
                        </div>
                        <div class="col-lg-2 col-md-2 mt-3">
                          <label for="" class="form-control-label">Image</label>
                          <br />
                          <base-button
                            href="#!"
                            class="btn btn-primary btn-color"
                            @click="openUploadModal(element.image)"
                            >Image</base-button
                          >
                        </div>
                        <div class="col-md-6">
                          <div
                            class="row"
                            v-for="(item, i) in element.tailleEtQuantites"
                            :key="i"
                          >
                            <div class="col-lg-6 col-md-6 mt-3">
                              <label for="" class="form-control-label"
                                >Taille</label
                              >
                              <select
                                name=""
                                id=""
                                class="form-control form-control-alternative"
                                v-model="item.taille"
                              >
                                <option>xs</option>
                                <option>s</option>
                                <option>M</option>
                                <option>L</option>
                                <option>XL</option>
                                <option>XLL</option>
                                <option>XLLL</option>
                              </select>
                            </div>
                            <div class="col-lg-6 col-md-6 mt-3">
                              <base-input
                                alternative=""
                                label="Quantité"
                                placeholder="Quantité"
                                input-classes="form-control-alternative"
                                v-model="item.quantite"
                              />
                            </div>
                            <div
                              class="col-lg-8 col-md-8 offset-md-4 offset-lg-4"
                            >
                              <div class="row">
                                <div class="col-lg-4 col-md-4">
                                  <base-button
                                    href="#!"
                                    class="btn btn-primary"
                                    @click="
                                      addInputDetail(
                                        'add',
                                        'vetements',
                                        index,
                                        i
                                      )
                                    "
                                    >+</base-button
                                  >
                                </div>
                                <div class="col-lg-4 col-md-4">
                                  <base-button
                                    href="#!"
                                    class="btn btn-primary"
                                    @click="
                                      addInputDetail(
                                        'delete',
                                        'vetements',
                                        index,
                                        i
                                      )
                                    "
                                    >-</base-button
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div :class="classValue3">
                      <div class="row">
                        <div class="col-lg-12 col-md-12">
                          <base-input
                            alternative=""
                            label="Prix"
                            placeholder="Prix"
                            input-classes="form-control-alternative"
                            v-model="model.productPrice"
                          />
                        </div>
                      </div>
                      <div
                        class="row"
                        v-for="(element, index) in model.tapis"
                        :key="index"
                      >
                        <div class="col-lg-1 col-md-1 mt-4">
                          <base-button
                            href="#!"
                            class="btn btn-primary"
                            @click="addInputArticle('add', 'tapis', index)"
                            >+</base-button
                          >
                        </div>
                        <div class="col-lg-1 col-md-1 mt-4">
                          <base-button
                            href="#!"
                            class="btn btn-primary"
                            @click="addInputArticle('delete', 'tapis', index)"
                            >-</base-button
                          >
                        </div>
                        <div class="col-lg-2 col-md-2 mt-3">
                          <label for="" class="form-control-label"
                            >Couleur</label
                          >
                          <br />
                          <base-button
                            href="#!"
                            class="btn btn-primary btn-color"
                            @click="openUploadModalColor(element)"
                            >Couleur</base-button
                          >
                        </div>
                        <div class="col-lg-2 col-md-2 mt-3">
                          <label for="" class="form-control-label">Image</label>
                          <br />
                          <base-button
                            href="#!"
                            class="btn btn-primary btn-color"
                            @click="openUploadModal(element.image)"
                            >Image</base-button
                          >
                        </div>
                        <div class="col-md-6">
                          <div
                            class="row"
                            v-for="(item, i) in element.widthAndHeight"
                            :key="i"
                          >
                            <div class="col-lg-4 col-md-4">
                              <base-input
                                alternative=""
                                label="Dimenssion - Largeur"
                                placeholder="Min"
                                input-classes="form-control-alternative"
                                v-model="item.widthMin"
                              />
                              <base-input
                                alternative=""
                                placeholder="Max"
                                input-classes="form-control-alternative"
                                v-model="item.widthMax"
                              />
                            </div>
                            <div class="col-lg-4 col-md-4">
                              <base-input
                                alternative=""
                                label="Dimenssion - Longueur"
                                placeholder="Min"
                                input-classes="form-control-alternative"
                                v-model="item.heightMin"
                              />
                              <base-input
                                alternative=""
                                placeholder="Max"
                                input-classes="form-control-alternative"
                                v-model="item.heightMax"
                              />
                            </div>
                            <div class="col-lg-4 col-md-4 mt-3">
                              <base-input
                                alternative=""
                                label="Quantité"
                                placeholder="Quantité"
                                input-classes="form-control-alternative"
                                v-model="item.quantite"
                              />
                            </div>
                            <div
                              class="col-lg-8 col-md-8 offset-md-4 offset-lg-4"
                            >
                              <div class="row">
                                <div class="col-lg-4 col-md-4">
                                  <base-button
                                    href="#!"
                                    class="btn btn-primary"
                                    @click="
                                      addInputDetail('add', 'tapis', index, i)
                                    "
                                    >+</base-button
                                  >
                                </div>
                                <div class="col-lg-4 col-md-4">
                                  <base-button
                                    href="#!"
                                    class="btn btn-primary"
                                    @click="
                                      addInputDetail(
                                        'delete',
                                        'tapis',
                                        index,
                                        i
                                      )
                                    "
                                    >-</base-button
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div :class="classValue4">
                      <div class="row">
                        <div class="col-lg-12 col-md-12">
                          <base-input
                            alternative=""
                            label="Prix"
                            placeholder="Prix"
                            input-classes="form-control-alternative"
                            v-model="model.productPrice"
                          />
                        </div>
                      </div>
                      <div
                        class="row"
                        v-for="(element, index) in model.autre"
                        :key="index"
                      >
                        <div class="col-lg-1 col-md-1 mt-4">
                          <base-button
                            href="#!"
                            class="btn btn-primary"
                            @click="addInputArticle('add', 'autre', index)"
                            >+</base-button
                          >
                        </div>
                        <div class="col-lg-1 col-md-1 mt-4">
                          <base-button
                            href="#!"
                            class="btn btn-primary"
                            @click="addInputArticle('delete', 'autre', index)"
                            >-</base-button
                          >
                        </div>
                        <div class="col-lg-2 col-md-2 mt-3">
                          <label for="" class="form-control-label"
                            >Couleur</label
                          >
                          <br />
                          <base-button
                            href="#!"
                            class="btn btn-primary btn-color"
                            @click="openUploadModalColor(element)"
                            >Couleur</base-button
                          >
                        </div>
                        <div class="col-lg-2 col-md-2 mt-3">
                          <label for="" class="form-control-label">Image</label>
                          <br />
                          <base-button
                            href="#!"
                            class="btn btn-primary btn-color"
                            @click="openUploadModal(element.image)"
                            >Image</base-button
                          >
                        </div>
                        <div class="col-md-6">
                          <div
                            class="row"
                            v-for="(item, i) in element.quantites"
                            :key="i"
                          >
                            <div class="col-lg-12 col-md-12 mt-3">
                              <base-input
                                alternative=""
                                label="Quantité"
                                placeholder="Quantité"
                                input-classes="form-control-alternative"
                                v-model="item.quantite"
                              />
                            </div>
                            <div
                              class="col-lg-8 col-md-8 offset-md-4 offset-lg-4"
                            >
                              <div class="row">
                                <div class="col-lg-4 col-md-4">
                                  <base-button
                                    href="#!"
                                    class="btn btn-primary"
                                    @click="
                                      addInputDetail('add', 'autre', index, i)
                                    "
                                    >+</base-button
                                  >
                                </div>
                                <div class="col-lg-4 col-md-4">
                                  <base-button
                                    href="#!"
                                    class="btn btn-primary"
                                    @click="
                                      addInputDetail(
                                        'delete',
                                        'autre',
                                        index,
                                        i
                                      )
                                    "
                                    >-</base-button
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div :class="classValue5">
                      <div
                        class="row"
                        v-for="(element, index) in model.bijoux"
                        :key="index"
                      >
                        <div class="col-lg-12 col-md-12">
                          <base-input
                            alternative=""
                            label="Prix"
                            placeholder="Prix"
                            input-classes="form-control-alternative"
                            v-model="model.productPrice"
                          />
                        </div>
                        <div class="col-lg-1 col-md-1 mt-4">
                          <base-button
                            href="#!"
                            class="btn btn-primary"
                            @click="addInputArticle('add', 'bijoux', index)"
                            >+</base-button
                          >
                        </div>
                        <div class="col-lg-1 col-md-1 mt-4">
                          <base-button
                            href="#!"
                            class="btn btn-primary"
                            @click="addInputArticle('delete', 'bijoux', index)"
                            >-</base-button
                          >
                        </div>
                        <div class="col-lg-2 col-md-2 mt-3">
                          <label for="" class="form-control-label">Image</label>
                          <br />
                          <base-button
                            href="#!"
                            class="btn btn-primary btn-color"
                            @click="openUploadModal(element.image)"
                            >Image</base-button
                          >
                        </div>
                        <div class="col-md-8">
                          <div
                            class="row"
                            v-for="(item, i) in element.tailleEtQuantites"
                            :key="i"
                          >
                            <div class="col-lg-4 col-md-4 mt-3">
                              <base-input
                                alternative=""
                                label="longueur"
                                placeholder="longueur"
                                input-classes="form-control-alternative"
                                v-model="item.longueur"
                              />
                            </div>
                            <div class="col-lg-4 col-md-4 mt-3">
                              <base-input
                                alternative=""
                                label="taille"
                                placeholder="taille"
                                input-classes="form-control-alternative"
                                v-model="item.taille"
                              />
                            </div>
                            <div class="col-lg-4 col-md-4 mt-3">
                              <base-input
                                alternative=""
                                label="Quantité"
                                placeholder="Quantité"
                                input-classes="form-control-alternative"
                                v-model="item.quantite"
                              />
                            </div>
                            <div
                              class="col-lg-8 col-md-8 offset-md-4 offset-lg-4"
                            >
                              <div class="row">
                                <div class="col-lg-4 col-md-4">
                                  <base-button
                                    href="#!"
                                    class="btn btn-primary"
                                    @click="
                                      addInputDetail('add', 'bijoux', index, i)
                                    "
                                    >+</base-button
                                  >
                                </div>
                                <div class="col-lg-4 col-md-4">
                                  <base-button
                                    href="#!"
                                    class="btn btn-primary"
                                    @click="
                                      addInputDetail(
                                        'delete',
                                        'bijoux',
                                        index,
                                        i
                                      )
                                    "
                                    >-</base-button
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <base-input
                        alternative=""
                        label="Dimenssion - Largeur"
                        placeholder="Min"
                        input-classes="form-control-alternative"
                        v-model="model.dimension.width[0]"
                      />
                      <base-input
                        alternative=""
                        placeholder="Max"
                        input-classes="form-control-alternative"
                        v-model="model.dimension.width[1]"
                      />
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <base-input
                        alternative=""
                        label="Dimenssion - Longueur"
                        placeholder="Min"
                        input-classes="form-control-alternative"
                        v-model="model.dimension.height[0]"
                      />
                      <base-input
                        alternative=""
                        placeholder="Max"
                        input-classes="form-control-alternative"
                        v-model="model.dimension.height[1]"
                      />
                    </div>
                   
                    <div class="col-lg-6 col-md-6 mt-3">
                      <label for="" class="form-control-label">Couleur</label>
                      <br />
                      <base-button
                        href="#!"
                        class="btn btn-primary btn-color"
                        @click="openUploadModalColor"
                        >Couleur</base-button
                      >
                    </div>
                 
                 
                    <div class="col-lg-4">
                      <base-button
                        href="#!"
                        class="btn btn-primary"
                        @click="openUploadModal"
                        >Image</base-button
                      >
                    </div>
                  </div>
                </div> -->
              </form>
            </template>
          </card>
          <div class="article-table">
            <tableArticle title="Articles"></tableArticle>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";
import tableArticle from "./Tables/tableArticle";
import Multiselect from "vue-multiselect";

export default {
  components: {
    tableArticle,
    CldContext,
    CldImage,
    Multiselect,
  },
  name: "user-profile",
  data() {
    return {
      model: {
        label: "",
        category: [],
        description: "",
        productPrice: 0,
        filtre: [],
        motCle: [],
        disponibility: [],
        orderType: "",
        typeArticle: "",
        bijoux: [
          {
            image: [],
            tailleEtQuantites: [
              {
                taille: "",
                longueur: "",
                quantite: 0,
              },
            ],
          },
        ],
        vetements: [
          {
            couleur: "",
            image: [],
            tailleEtQuantites: [
              {
                taille: "",
                quantite: 0,
              },
            ],
          },
        ],
        chaussure: [
          {
            couleur: "",
            image: [],
            pointureEtQuantites: [
              {
                pointure: "",
                quantite: 0,
              },
            ],
          },
        ],
        tapis: [
          {
            couleur: "",
            image: [],
            widthAndHeight: [
              {
                widthMax: 0,
                widthMin: 0,
                heightMax: 0,
                heightMin: 0,
                quantite: 0,
              },
            ],
          },
        ],
        autre: [
          {
            couleur: "",
            image: [],
            quantites: [
              {
                quantite: 0,
              },
            ],
          },
        ],
      },
      optionsCategorie: [
        { name: "Anniversaire-Homme", code: 1 },
        { name: "Anniversaire-Femme", code: 2 },
        { name: "Anniversaire-Enfant", code: 3 },
        { name: "Anniversary-Homme", code: 4 },
        { name: "Anniversary-Femme", code: 5 },
        { name: "Naissance-Maman", code: 6 },
        { name: "Naissance-Bebe", code: 7 },
        { name: "Naissance-Grand-frere-soeur", code: 8 },
        { name: "Cadeau-daffaire", code: 9 },
        { name: "Occasions-Cadeau-mariage", code: 10 },
        { name: "Occasions-Cadeau-Reussite", code: 11 },
        { name: "Occasions-Cadeau-Demenagement", code: 12 },
        { name: "Occasions-Cadeau-visite", code: 13 },
      ],
      options: [
        { name: "Hommes", code: 1 },
        { name: "Femmes", code: 2 },
        { name: "Enfants", code: 3 },
        { name: "Deco", code: 4 },
        { name: "Accessoire", code: 5 },
        { name: "Moulid", code: 6 },
        { name: "Noël", code: 7 },
        { name: "Saint Valentin", code: 8 },
        { name: "Fête des grands-mères", code: 9 },
        { name: "Eid alFitr", code: 10 },
        { name: "Fête des Mères ", code: 11 },
        { name: "Fête des Pères", code: 12 },
        { name: "Fête nationale de la femme", code: 13 },
        { name: "Rentrée scolaire ", code: 14 },
        { name: "Octobre rose", code: 15 },
        { name: "Black friday", code: 16 },

      ],
      classValue1: "elements-product col-md-12",
      classValue2: "elements-product col-md-12",
      classValue3: "elements-product col-md-12",
      classValue4: "elements-product col-md-12",
      classValue5: "elements-product col-md-12",
    };
  },
  created() {
    // this.numberOfDetails[0]=1;
  },
  methods: {
    // numberOfDetailsProduct(i){
    //     this.numberOfDetails[i]=1
    //     return this.numberOfDetails[i]
    // },

    displayElement(arg) {
      this.model.typeArticle = arg;
      if (arg == "chaussure") {
        this.classValue1 = "chaussure col-md-12";
        this.classValue2 = "elements-product col-md-12";
        this.classValue3 = "elements-product col-md-12";
        this.classValue4 = "elements-product col-md-12";
        this.classValue5 = "elements-product col-md-12";
      } else if (arg == "vetements") {
        this.classValue2 = "vetements col-md-12";
        this.classValue1 = "elements-product col-md-12";
        this.classValue3 = "elements-product col-md-12";
        this.classValue4 = "elements-product col-md-12";
        this.classValue5 = "elements-product col-md-12";
      } else if (arg == "tapis") {
        this.classValue3 = "tapis col-md-12";
        this.classValue2 = "elements-product col-md-12";
        this.classValue1 = "elements-product col-md-12";
        this.classValue4 = "elements-product col-md-12";
        this.classValue5 = "elements-product col-md-12";
      } else if (arg == "autre") {
        this.classValue4 = "autre col-md-12";
        this.classValue2 = "elements-product col-md-12";
        this.classValue3 = "elements-product col-md-12";
        this.classValue1 = "elements-product col-md-12";
        this.classValue5 = "elements-product col-md-12";
      } else if (arg == "bijoux") {
        this.classValue4 = "elements-product col-md-12";
        this.classValue5 = "autre col-md-12";
        this.classValue2 = "elements-product col-md-12";
        this.classValue3 = "elements-product col-md-12";
        this.classValue1 = "elements-product col-md-12";
      }
      console.log(this.model.typeArticle);
    },
    addInputArticle(type, productName, index) {
      // this.numberOfDetails[index+1]=1;
      console.log(index, productName);
      if (productName == "chaussure") {
        if (type == "add") {
          // this.numberOfArticle++;
          this.model.chaussure.push({
            couleur: "",
            image: [],
            pointureEtQuantites: [
              {
                pointure: "",
                quantite: 0,
                prix: 0,
              },
            ],
          });
        } else if (type == "delete" && this.model.chaussure.length > 1) {
          // this.numberOfArticle--;
          this.model.chaussure = this.model.chaussure.filter(
            (value, j) => j != index
          );
        } else {
          this.$notifications.notify({
            message: "Impossible de réaliser cette action.",
            type: "danger",
          });
        }
      } else if (productName == "vetements") {
        if (type == "add") {
          // this.numberOfArticle++;
          this.model.vetements.push({
            couleur: "",
            image: [],
            tailleEtQuantites: [
              {
                taille: "",
                quantite: 0,
              },
            ],
          });
        } else if (type == "delete" && this.model.vetements.length > 1) {
          // this.numberOfArticle--;
          this.model.vetements = this.model.vetements.filter(
            (value, j) => j != index
          );
        } else {
          this.$notifications.notify({
            message: "Impossible de réaliser cette action.",
            type: "danger",
          });
        }
      } else if (productName == "bijoux") {
        if (type == "add") {
          // this.numberOfArticle++;
          this.model.bijoux.push({
            couleur: "",
            image: [],
            tailleEtQuantites: [
              {
                taille: "",
                longueur: "",
                quantite: 0,
                prix: 0,
              },
            ],
          });
        } else if (type == "delete" && this.model.bijoux.length > 1) {
          // this.numberOfArticle--;
          this.model.bijoux = this.model.bijoux.filter(
            (value, j) => j != index
          );
        } else {
          this.$notifications.notify({
            message: "Impossible de réaliser cette action.",
            type: "danger",
          });
        }
      } else if (productName == "tapis") {
        if (type == "add") {
          // this.numberOfArticle++;
          this.model.tapis.push({
            couleur: "",
            image: [],
            widthAndHeight: [
              {
                widthMax: 0,
                widthMin: 0,
                heightMax: 0,
                heightMin: 0,
                quantite: 0,
              },
            ],
          });
        } else if (type == "delete" && this.model.tapis.length > 1) {
          // this.numberOfArticle--;
          this.model.tapis = this.model.tapis.filter((value, j) => j != index);
        } else {
          this.$notifications.notify({
            message: "Impossible de réaliser cette action.",
            type: "danger",
          });
        }
      } else if (productName == "autre") {
        if (type == "add") {
          // this.numberOfArticle++;
          this.model.autre.push({
            couleur: "",
            image: [],
            quantites: [
              {
                quantite: 0,
              },
            ],
          });
        } else if (type == "delete" && this.model.autre.length > 1) {
          // this.numberOfArticle--;
          this.model.autre = this.model.autre.filter((value, j) => j != index);
        } else {
          this.$notifications.notify({
            message: "Impossible de réaliser cette action.",
            type: "danger",
          });
        }
      }
    },
    addInputDetail(type, productName, index, i) {
      if (productName == "chaussure") {
        if (type == "add") {
          this.model.chaussure[index].pointureEtQuantites.push({
            pointure: "",
            quantite: 0,
          });
          // this.numberOfDetails[index]=this.numberOfDetails[index]+1;
        } else if (
          type == "delete" &&
          this.model.chaussure[index].pointureEtQuantites.length > 1
        ) {
          this.model.chaussure[
            index
          ].pointureEtQuantites = this.model.chaussure[
            index
          ].pointureEtQuantites.filter((value, j) => j != i);
        } else {
          this.$notifications.notify({
            message: "Impossible de réaliser cette action.",
            type: "danger",
          });
        }
      } else if (productName == "vetements") {
        if (type == "add") {
          this.model.vetements[index].tailleEtQuantites.push({
            taille: "",
            quantite: 0,
          });
          // this.numberOfDetails[index]=this.numberOfDetails[index]+1;
        } else if (
          type == "delete" &&
          this.model.vetements[index].tailleEtQuantites.length > 1
        ) {
          this.model.vetements[index].tailleEtQuantites = this.model.vetements[
            index
          ].tailleEtQuantites.filter((value, j) => j != i);
        } else {
          this.$notifications.notify({
            message: "Impossible de réaliser cette action.",
            type: "danger",
          });
        }
      } else if (productName == "bijoux") {
        if (type == "add") {
          this.model.bijoux[index].tailleEtQuantites.push({
            taille: "",
            quantite: 0,
          });
          // this.numberOfDetails[index]=this.numberOfDetails[index]+1;
        } else if (
          type == "delete" &&
          this.model.bijoux[index].tailleEtQuantites.length > 1
        ) {
          this.model.bijoux[index].tailleEtQuantites = this.model.bijoux[
            index
          ].tailleEtQuantites.filter((value, j) => j != i);
        } else {
          this.$notifications.notify({
            message: "Impossible de réaliser cette action.",
            type: "danger",
          });
        }
      } else if (productName == "tapis") {
        if (type == "add") {
          this.model.tapis[index].widthAndHeight.push({
            widthMax: 0,
            widthMin: 0,
            heightMax: 0,
            heightMin: 0,
            quantite: 0,
          });
          // this.numberOfDetails[index]=this.numberOfDetails[index]+1;
        } else if (
          type == "delete" &&
          this.model.tapis[index].widthAndHeight.length > 1
        ) {
          this.model.tapis[index].widthAndHeight = this.model.tapis[
            index
          ].widthAndHeight.filter((value, j) => j != i);
        } else {
          this.$notifications.notify({
            message: "Impossible de réaliser cette action.",
            type: "danger",
          });
        }
      } else if (productName == "autre") {
        if (type == "add") {
          this.model.autre[index].quantites.push({
            quantite: 0,
          });
          // this.numberOfDetails[index]=this.numberOfDetails[index]+1;
        } else if (
          type == "delete" &&
          this.model.autre[index].quantites.length > 1
        ) {
          this.model.autre[index].quantites = this.model.autre[
            index
          ].quantites.filter((value, j) => j != i);
        } else {
          this.$notifications.notify({
            message: "Impossible de réaliser cette action.",
            type: "danger",
          });
        }
      }
      console.log(index);
    },
    addCategory(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.model.category.push(tag);
    },
    // addQuantity(newTag) {
    //   const tag = {
    //     name: newTag,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    //   };
    //   this.options.push(tag);
    //   this.model.quantity.push(tag.name);
    // },
    // addTaille(newTag) {
    //   const tag = {
    //     name: newTag,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    //   };
    //   this.options.push(tag);
    //   this.model.taille.push(tag.name);
    // },
    addFiltre(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.model.filtre.push(tag);
    },
    // addPointure(newTag) {
    //   const tag = {
    //     name: newTag,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    //   };
    //   this.options.push(tag);
    //   this.model.pointure.push(tag.name);
    // },
    addMotCle(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.model.motCle.push(tag);
    },

    openUploadModalColor(element) {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpy824jnw", upload_preset: "vlgevino" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              element.couleur = this.publicId;
              console.log(element.couleur);
            }
          }
        )
        .open();
      // this.model.colors.push(this.color)
    },
    openUploadModal(image) {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpy824jnw", upload_preset: "vlgevino" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              image.push(this.publicId);
            }
          }
        )
        .open();
    },
    AddArticle: function () {
      console.log("model", this.model);
      axios
        .post(
          "https://giftstore-blog.herokuapp.com/api/product/add",
          this.model
        )
        .then((response) => {
          console.log(response);
          this.$notifications.notify({
            message: "Element bien ajouté.",
            type: "success",
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.$notifications.notify({
            message: "Erreur lors de l'ajout'.",
            type: "danger",
          });
        });
    },
  },
};
</script>
<style>
.bg-gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #55031c 100%) !important;
}
.gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #dc0746 100%) !important;
}
.email-screen img {
  width: 100%;
}
.btn-add-user {
  margin-top: 2rem;
}
.article-table {
  margin-top: 5%;
}
.form-control-alternative .multiselect__content-wrapper .multiselect__content {
  background: #172b4d !important;
  cursor: pointer;
  width: 70%;
  z-index: 99999;
  border: none;
  color: #f7fafc;
  position: absolute;
}
.multiselect__input {
  margin-top: 20px;
  z-index: 99999;
  border: none;
}
.multiselect__tag {
  background: #172b4d;
  margin-right: 1%;
  padding: 1%;
  border-radius: 5px;
  color: #f7fafc;
}
.btn-color {
  width: 100%;
}
.mt-4 {
  margin-top: 3.8% !important;
}
.elements-product {
  display: none;
}
.chaussure {
  display: block !important;
}
.vetements {
  display: block !important;
}
.tapis {
  display: block !important;
}
.autre {
  display: block !important;
}
</style>
