<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Événement</h3>
                </div>
                <div class="col-4 text-right">
                  <base-button
                    href="#!"
                    class="btn btn-primary"
                    @click="addBestArticles()"
                    >enregistrer</base-button
                  >
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <div class="pl-lg-4">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 mt-3">
                      <label for="" class="form-control-label">Événement</label>
                      <select
                        name=""
                        id=""
                        class="form-control form-control-alternative"
                        v-model="evenement.category"
                      >
                        <option></option>
                        <option>Noël</option>
                        <option>Moulid</option>
                        <option>Saint Valentin</option>
                        <option>"Fête des grands-mères</option>
                        <option>Eid alFitr</option>
                        <option>Fête des Mères</option>
                        <option>Fête des Pères</option>
                        <option>Fête nationale de la femme</option>
                        <option>Rentrée scolaire</option>
                        <option>Octobre rose</option>
                        <option>Black friday</option>
                      </select>
                    </div>
                     <div class="col-lg-4 col-md-4 mt-3">
                      <base-input
                        alternative=""
                        label="Description"
                        placeholder="Description"
                        input-classes="form-control-alternative"
                        v-model="evenement.description"
                      />
                    </div>
                    <div class="col-lg-4 col-md-4 mt-3">
                      <label for="" class="form-control-label">Image</label>
                      <br />
                      <base-button
                        href="#!"
                        class="btn btn-primary btn-color"
                        @click="openUploadModal(0)"
                        id="0"
                        >Image</base-button
                      >
                    </div>
                  </div>
                </div>
              </form>
            </template>
          </card>
          <div class="article-table">
            <tableBestArticles title="Événement"></tableBestArticles>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";
import tableBestArticles from "./Tables/tableBestArticles";
import Multiselect from "vue-multiselect";

export default {
  components: {
    tableBestArticles,
    CldContext,
    CldImage,
    Multiselect,
  },
  name: "events",
  data() {
    return {
      evenement: 
        {
          category: "",
          description:"",
          image: "",
          state:false
        },
    };
  },
  // created() {
  //   axios
  //     .post("https://giftstore-blog.herokuapp.com/api/bestSelling/getAll")
  //     .then((response) => {
  //       console.log("articles", response);
  //       this.bestArticlesToSend = response.data.bestSelling;
  //       var i = 0;
  //       this.bestArticlesToSend.forEach((element) => {
  //         this.model.category[i] = element.category;
  //         this.model.pictures[i] = element.image;
  //         i++;
  //         // for(const item of this.article){
  //         //     item.category=element.category
  //         //     item.image=element.image
  //         //     item.number=element.number

  //         // }
  //       });
  //     })
  //     .catch((error) => {
  //       this.errorMessage = error.message;
  //       console.error("There was an error!", error);
  //     });
  // },
  methods: {
    openUploadModal() {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpy824jnw", upload_preset: "vlgevino"},
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              this.evenement.image = this.publicId;
            }
          }
        )
        .open();
    },
    addBestArticles: function() {
console.log( "test",this.evenement)
      axios
        .post(
          "https://giftstore-blog.herokuapp.com/api/bestSelling/add",
          this.evenement
        )
        .then((response) => {
          console.log(response);
          this.$notifications.notify({
            message: "Element bien ajouté.",
            type: "success",
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.$notifications.notify({
            message: "Erreur lors de l'ajout'.",
            type: "danger",
          });
        });
    },
  },
};
</script>
<style>
.bg-gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #55031c 100%) !important;
}
.gradient-success {
  background: linear-gradient(87deg, #dc0746 0, #dc0746 100%) !important;
}
.email-screen img {
  width: 100%;
}
.btn-add-user {
  margin-top: 2rem;
}
.article-table {
  margin-top: 5%;
}
.form-control-alternative .multiselect__content-wrapper .multiselect__content {
  background: #172b4d !important;
  cursor: pointer;
  width: 70%;
  z-index: 99999;
  border: none;
  color: #f7fafc;
  position: absolute;
}
.multiselect__input {
  margin-top: 20px;
  z-index: 99999;
  border: none;
}
.multiselect__tag {
  background: #172b4d;
  margin-right: 1%;
  padding: 1%;
  border-radius: 5px;
  color: #f7fafc;
}
.btn-color {
  width: 100%;
}
</style>
