<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Nombre de blog"
            type="gradient-red"
            sub-title="10"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0 stat-cards "
          >
      
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Nombre des utilisateurs"
            type="gradient-red"
            sub-title="6"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0 stat-cards"
          >
           
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Nombre des événements"
            type="gradient-red"
            sub-title="4"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0 stat-cards"
          >
          
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Nombre de produits"
            type="gradient-red"
            sub-title="22"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0 stat-cards"
          >
          
          </stats-card>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-xl-3 col-lg-3">
          <stats-card
            title="Nombre de commande"
            type="gradient-orange"
            sub-title="6"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0 stat-cards"
          >
           
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-3">
          <stats-card
            title="Commande en attente"
            type="gradient-orange"
            sub-title="2"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0 stat-cards"
          >
           
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-3">
          <stats-card
            title="Commande accepter"
            type="gradient-orange"
            sub-title="3"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0 stat-cards"
          >
            
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-3">
          <stats-card
            title="Commande refuser"
            type="gradient-orange"
            sub-title="1"
            icon="ni ni-chart-bar-32"
            class="mb-4 mb-xl-0 stat-cards"
          >
          
          </stats-card>
        </div>
      </div>
           <hr />
      <div class="row">
        <div class="col-xl-6 col-lg-6">
          <stats-card
            title="Payer par carte"
            type="gradient-info"
            sub-title="6"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0 stat-cards"
          >
            <!-- <template slot="footer">
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 12.18%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
        <div class="col-xl-6 col-lg-6">
          <stats-card
            title="Carte cadeau"
            type="gradient-info"
            sub-title="2"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0 stat-cards"
          >
            <!-- <template slot="footer">
              <span class="text-success mr-2"
                ><i class="fa fa-arrow-up"></i> 12.18%</span
              >
              <span class="text-nowrap">Since last month</span>
            </template> -->
          </stats-card>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>
                <h5 class="h3 text-white mb-0">Nombre de commande</h5>
              </div>
              <div class="col">
                <ul class="nav nav-pills justify-content-end">
                  <li class="nav-item mr-2 mr-md-0">
                    <a
                      class="nav-link py-2 px-3"
                      href="#"
                      :class="{ active: bigLineChart.activeIndex === 0 }"
                      @click.prevent="initBigChart(0)"
                    >
                      <span class="d-none d-md-block">Month</span>
                      <span class="d-md-none">M</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link py-2 px-3"
                      href="#"
                      :class="{ active: bigLineChart.activeIndex === 1 }"
                      @click.prevent="initBigChart(1)"
                    >
                      <span class="d-none d-md-block">Week</span>
                      <span class="d-md-none">W</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <line-chart
              :height="350"
              ref="bigChart"
              :chart-data="bigLineChart.chartData"
              :extra-options="bigLineChart.extraOptions"
            >
            </line-chart>
          </card>
        </div>

        <!-- <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Performance</h6>
                <h5 class="h3 mb-0">Total orders</h5>
              </div>
            </div>

            <bar-chart
              :height="350"
              ref="barChart"
              :chart-data="redBarChart.chartData"
            >
            </bar-chart>
          </card>
        </div> -->
      </div>
      <!-- End charts-->

      <!--Tables-->
      <!-- <div class="row mt-5">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <page-visits-table></page-visits-table>
        </div>
         <div class="col-xl-4">
                    <social-traffic-table></social-traffic-table>
                </div> 
      </div>-->
      <!--End tables-->
    </div>
  </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";

// Tables
import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
import PageVisitsTable from "./Dashboard/PageVisitsTable";

export default {
  components: {
    LineChart,
    BarChart,
    PageVisitsTable,
    SocialTrafficTable,
  },
  data() {
    return {
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
        chartData: {
          datasets: [],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      redBarChart: {
        chartData: {
          labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Sales",
              data: [25, 20, 30, 22, 17, 29],
            },
          ],
        },
      },
    };
  },
  methods: {
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            label: "Performance",
            data: this.bigLineChart.allData[index],
          },
        ],
        labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      };
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
  },
  mounted() {
    this.initBigChart(0);
  },
};
</script>
<style>
.stat-cards h5 {
  font-size: 11px !important;
}
</style>
