<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col-md-2 text-right btn-table-blog">
          <select
            name=""
            id=""
            class="form-control form-control-alternative"
            v-model="model.FilterCategorie"
            @change="filterDataArray('filterCategorie')"
          >
            <option>Tous</option>
            <option>Anniversaire-Homme</option>
            <option>Anniversaire-Femme</option>
            <option>Anniversaire-Enfant</option>
            <option>Anniversary-Homme</option>
            <option>Anniversary-Femme</option>
            <option>Naissance-Maman</option>
            <option>Naissance-Bebe</option>
            <option>Naissance-Grand-frere-soeur</option>
            <option>Cadeau-daffaire</option>
            <option>Occasions-Cadeau-mariage</option>
            <option>Occasions-Cadeau-Reussite</option>
            <option>Occasions-Cadeau-Demenagement</option>
            <option>Occasions-Cadeau-visite</option>
          </select>
        </div>
        <div class="col-md-2 text-right btn-table-blog">
          <input
            @input="filterDataArray('filterAll')"
            v-model="model.FilterAll"
            type="text"
            placeholder="Recherche"
            class="form-control form-control-alternative"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="sortedData"
      >
        <template slot="columns">
          <th>Nom</th>
          <th>Catégorie</th>
          <th>Description</th>
          <th>Action</th>
        </template>

        <template slot-scope="{ row }">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.label }}</span>
              </div>
            </div>
          </th>
          <td style="">
            <p class="Description-p" v-html="row.formattedCategory"></p>
          </td>
          <td>
            <p class="Description-p">{{ row.description }}</p>
          </td>

          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
              <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-ellipsis-v"></i>
              </a>
              <template>
                <a
                  class="dropdown-item"
                  href="#"
                  v-bind:id="row._id"
                  @click="deleteProduct(row._id)"
                  >Supprimer</a
                >
                <a
                  class="dropdown-item"
                  v-bind:id="row._id"
                  href="#"
                  @click="(modals.modal3 = true), showDataElement(row)"
                  >Modifier</a
                >
              </template>
            </base-dropdown>
          </td>
          <modal :show.sync="modals.modal3">
            <h2 slot="header" class="modal-title" id="modal-title-default">
              Modifier le produit
            </h2>
            <div class="row">
              <div class="col-lg-12">
                <base-input
                  alternative=""
                  label="Nom du produit"
                  placeholder="Nom du produit"
                  input-classes="form-control-alternative"
                  v-model="modelUpdate.label"
                />
              </div>
              <div class="col-lg-12">
                <base-input alternative="" label="Déscription">
                  <textarea
                    rows="4"
                    class="form-control form-control-alternative"
                    placeholder="Description  ..."
                    v-model="modelUpdate.description"
                  ></textarea>
                </base-input>
              </div>
              <!-- <div class="col-lg-12">
                <label for="" class="form-control-label">Catégorie</label>

                <base-input
                  alternative=""
                  placeholder="Catégorie"
                  input-classes="form-control-alternative"
                >
                  <multiselect
                    tag-placeholder=" Catégorie"
                    placeholder=" Catégorie"
                    label="name"
                    track-by="code"
                    :options="options"
                    :multiple="true"
                    :taggable="true"
                    @tag="addCategory"
                    class="form-control form-control-alternative"
                  ></multiselect>
                </base-input>
              </div>
              <div class="col-lg-12">
                <label for="" class="form-control-label">Filter</label>

                <base-input
                  alternative=""
                  placeholder="Filter"
                  input-classes="form-control-alternative"
                >
                  <multiselect
                    v-model="modelUpdate.filtre"
                    tag-placeholder=" Filter"
                    placeholder=" Filter"
                    label="name"
                    track-by="code"
                    :options="options"
                    :multiple="true"
                    :taggable="true"
                    @tag="addFiltre"
                    class="form-control form-control-alternative"
                  ></multiselect>
                </base-input>
              </div>
              <div class="col-lg-12">
                <label for="" class="form-control-label">Mot clé</label>

                <base-input
                  alternative=""
                  placeholder="Mot clé"
                  input-classes="form-control-alternative"
                >
                  <multiselect
                    v-model="modelUpdate.motCle"
                    tag-placeholder="Mot clé"
                    placeholder="Mot clé"
                    label="name"
                    track-by="code"
                    :options="options"
                    :multiple="true"
                    :taggable="true"
                    @tag="addMotCle"
                    class="form-control form-control-alternative"
                  ></multiselect>
                </base-input>
              </div> -->
              <!-- <div class="col-lg-12 col-md-12">
                <label for="" class="form-control-label">Type commande</label>
                <br />
                <div class="text-center">
                  Lancer commande
                  <input
                    v-model="modelUpdate.type"
                    value="lancer commande"
                    type="radio"
                    name="typeCommande"
                    id=""
                    class="form-control-alternative"
                  />
                  pré-vente
                  <input
                    v-model="modelUpdate.type"
                    value="pré-vente"
                    type="radio"
                    name="typeCommande"
                    id=""
                    class="form-control-alternative"
                  />
                  Commander
                  <input
                    v-model="modelUpdate.type"
                    value="Commander"
                    type="radio"
                    name="typeCommande"
                    id=""
                    class="form-control-alternative"
                  />
                </div>
              </div> -->
              <!-- <div class="col-lg-12">
                      <label for="" class="form-control-label"
                        >Date de disponibilité (début)</label
                      >
                      <input
                      :v-model="dateProduct(0)"
                        type="date"
                        name=""
                        id=""
                        class="form-control form-control-alternative"
                      />
                    </div>
                    <div class="col-lg-12">
                      <label for="" class="form-control-label"
                        >Date de disponibilité (fin)</label
                      >
                      <input
                        :v-model="dateProduct(1)"
                        type="date"
                        name=""
                        id=""
                        class="form-control form-control-alternative"
                      />
                    </div> -->

              <div class="col-md-12">
                <div class="row" v-if="typeModel == 1">
                  <div class="col-lg-12 mt-3">
                    <base-input
                      alternative=""
                      label="type de l'article"
                      placeholder="type de l'article"
                      input-classes="form-control-alternative"
                      v-model="modelUpdate.typeArticle"
                      disabled
                    />
                  </div>
                  <div class="col-lg-12 mt-3">
                    <base-input
                      alternative=""
                      label="Prix"
                      placeholder="Prix"
                      input-classes="form-control-alternative"
                      v-model="modelUpdate.productPrice"
                    />
                  </div>
                  <div
                    class="row"
                    v-for="(element, index) in modelUpdate.autre"
                    :key="index"
                  >
                    <hr style="background: #dc0746; width: 80%" />
                    <div class="col-md-12">
                      <label for="" class="form-control-label">image</label>
                    </div>
                    <div
                      class="col-lg-4 col-md-4 update-image-section text-center"
                      v-for="(image, index) in element.image"
                      :key="index"
                    >
                      <div class="updata-image">
                        <cld-context cloudName="dpy824jnw">
                          <cld-image :publicId="image" crop="scale" />
                        </cld-context>
                      </div>
                      <p
                        class="delete-imageToUpdate"
                        @click="deleteImageToUpdate(element.image, image)"
                      >
                        Supprimer l'image
                      </p>
                    </div>
                    <div class="col-md-12">
                      <base-button
                        href="#!"
                        class="btn btn-primary btn-color"
                        @click="openUploadModal(element.image)"
                        >Image</base-button
                      >
                    </div>

                    <div class="col-md-12">
                      <label for="" class="form-control-label">couleur</label>
                    </div>

                    <div
                      class="col-lg-4 col-md-4 update-image-section text-center"
                    >
                      <div class="updata-image">
                        <cld-context cloudName="dpy824jnw">
                          <cld-image :publicId="element.couleur" crop="scale" />
                        </cld-context>
                      </div>
                      <!-- <p
                    class="delete-imageToUpdate"
                    @click="deleteColorToUpdate(image)"
                  >
                    Supprimer l'image
                  </p> -->
                      <base-button
                        href="#!"
                        class="btn btn-primary btn-color"
                        @click="openUploadModalColor(element)"
                        >couleur</base-button
                      >
                    </div>

                    <div class="col-lg-12">
                      <base-input
                        alternative=""
                        label="Quantité"
                        placeholder="Quantité"
                        input-classes="form-control-alternative"
                        v-model="element.quantites[0].quantite"
                      />
                    </div>
                  </div>
                </div>

                <div class="row" v-if="typeModel == 2">
                  <div class="col-lg-12 mt-3">
                    <base-input
                      alternative=""
                      label="type de l'article"
                      placeholder="type de l'article"
                      input-classes="form-control-alternative"
                      v-model="modelUpdate.typeArticle"
                      disabled
                    />
                  </div>

                  <div
                    class="row"
                    v-for="(element, index) in modelUpdate.chaussure"
                    :key="index"
                  >
                    <hr style="background: #dc0746; width: 80%" />
                    <div class="col-md-12">
                      <label for="" class="form-control-label">image</label>
                    </div>
                    <div
                      class="col-lg-4 col-md-4 update-image-section text-center"
                      v-for="(image, index) in element.image"
                      :key="index"
                    >
                      <div class="updata-image">
                        <cld-context cloudName="dpy824jnw">
                          <cld-image :publicId="image" crop="scale" />
                        </cld-context>
                      </div>
                      <p
                        class="delete-imageToUpdate"
                        @click="deleteImageToUpdate(element.image, image)"
                      >
                        Supprimer l'image
                      </p>
                    </div>
                    <div class="col-md-12">
                      <base-button
                        href="#!"
                        class="btn btn-primary btn-color"
                        @click="openUploadModal(element.image)"
                        >Image</base-button
                      >
                    </div>

                    <div class="col-md-12">
                      <label for="" class="form-control-label">couleur</label>
                    </div>

                    <div
                      class="col-lg-4 col-md-4 update-image-section text-center"
                    >
                      <div class="updata-image">
                        <cld-context cloudName="dpy824jnw">
                          <cld-image :publicId="element.couleur" crop="scale" />
                        </cld-context>
                      </div>
                      <!-- <p
                    class="delete-imageToUpdate"
                    @click="deleteColorToUpdate(image)"
                  >
                    Supprimer l'image
                  </p> -->
                      <base-button
                        href="#!"
                        class="btn btn-primary btn-color"
                        @click="openUploadModalColor(element)"
                        >couleur</base-button
                      >
                    </div>
                    <div
                      class="col-md-12 row"
                      v-for="(subElment, index) in element.pointureEtQuantites"
                      :key="index"
                    >
                      <div class="col-lg-4">
                        <base-input
                          alternative=""
                          label="Quantité"
                          placeholder="Quantité"
                          input-classes="form-control-alternative"
                          v-model="subElment.quantite"
                        />
                      </div>
                      <div class="col-lg-4">
                        <base-input
                          alternative=""
                          label="pointure"
                          placeholder="pointure"
                          input-classes="form-control-alternative"
                          v-model="subElment.pointure"
                        />
                      </div>
                      <div class="col-lg-4">
                        <base-input
                          alternative=""
                          label="prix"
                          placeholder="prix"
                          input-classes="form-control-alternative"
                          v-model="subElment.prix"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="typeModel == 3">
                  <div class="col-lg-12 mt-3">
                    <base-input
                      alternative=""
                      label="type de l'article"
                      placeholder="type de l'article"
                      input-classes="form-control-alternative"
                      v-model="modelUpdate.typeArticle"
                      disabled
                    />
                  </div>

                  <div
                    class="row"
                    v-for="(element, index) in modelUpdate.bijoux"
                    :key="index"
                  >
                    <hr style="background: #dc0746; width: 80%" />
                    <div class="col-md-12">
                      <label for="" class="form-control-label">image</label>
                    </div>
                    <div
                      class="col-lg-4 col-md-4 update-image-section text-center"
                      v-for="(image, indexImage) in element.image"
                      :key="indexImage"
                    >
                      <div class="updata-image">
                        <cld-context cloudName="dpy824jnw">
                          <cld-image :publicId="image" crop="scale" />
                        </cld-context>
                      </div>
                      <p
                        class="delete-imageToUpdate"
                        @click="deleteImageToUpdate(element.image, image)"
                      >
                        Supprimer l'image
                      </p>
                    </div>
                    <div class="col-md-12">
                      <base-button
                        href="#!"
                        class="btn btn-primary btn-color"
                        @click="openUploadModal(element.image)"
                        >Image</base-button
                      >
                    </div>

                    <div
                      class="col-md-12 row"
                      v-for="(subElment, indexSub) in element.tailleEtQuantites"
                      :key="indexSub"
                    >
                      <div class="col-lg-6 col-md-6">
                        <base-input
                          alternative=""
                          label="taille"
                          placeholder="taille"
                          input-classes="form-control-alternative"
                          v-model="subElment.taille"
                        />
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <base-input
                          alternative=""
                          label="longueur"
                          placeholder="longueur"
                          input-classes="form-control-alternative"
                          v-model="subElment.longueur"
                        />
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <base-input
                          alternative=""
                          label="prix"
                          placeholder="prix"
                          input-classes="form-control-alternative"
                          v-model="subElment.prix"
                        />
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <base-input
                          alternative=""
                          label="Quantité"
                          placeholder="Quantité"
                          input-classes="form-control-alternative"
                          v-model="subElment.quantite"
                        />
                      </div>
                      <hr style="background: #dc0746; width: 80%" />
                    </div>
                  </div>
                </div>

                <div class="row" v-if="typeModel == 4">
                  <div class="col-lg-12 mt-3">
                    <base-input
                      alternative=""
                      label="type de l'article"
                      placeholder="type de l'article"
                      input-classes="form-control-alternative"
                      v-model="modelUpdate.typeArticle"
                      disabled
                    />
                  </div>

                  <div class="col-lg-12 mt-3">
                    <base-input
                      alternative=""
                      label="Prix"
                      placeholder="Prix"
                      input-classes="form-control-alternative"
                      v-model="modelUpdate.productPrice"
                      disabled
                    />
                  </div>

                  <div
                    class="row"
                    v-for="(element, index) in modelUpdate.tapis"
                    :key="index"
                  >
                    <hr style="background: #dc0746; width: 80%" />
                    <div class="col-md-12">
                      <label for="" class="form-control-label">image</label>
                    </div>
                    <div
                      class="col-lg-4 col-md-4 update-image-section text-center"
                      v-for="(image, index) in element.image"
                      :key="index"
                    >
                      <div class="updata-image">
                        <cld-context cloudName="dpy824jnw">
                          <cld-image :publicId="image" crop="scale" />
                        </cld-context>
                      </div>
                      <p
                        class="delete-imageToUpdate"
                        @click="deleteImageToUpdate(element.image, image)"
                      >
                        Supprimer l'image
                      </p>
                    </div>
                    <div class="col-md-12">
                      <base-button
                        href="#!"
                        class="btn btn-primary btn-color"
                        @click="openUploadModal(element.image)"
                        >Image</base-button
                      >
                    </div>

                    <div class="col-md-12">
                      <label for="" class="form-control-label">couleur</label>
                    </div>

                    <div
                      class="col-lg-4 col-md-4 update-image-section text-center"
                    >
                      <div class="updata-image">
                        <cld-context cloudName="dpy824jnw">
                          <cld-image :publicId="element.couleur" crop="scale" />
                        </cld-context>
                      </div>
                      <!-- <p
                    class="delete-imageToUpdate"
                    @click="deleteColorToUpdate(image)"
                  >
                    Supprimer l'image
                  </p> -->
                      <base-button
                        href="#!"
                        class="btn btn-primary btn-color"
                        @click="openUploadModalColor(element)"
                        >couleur</base-button
                      >
                    </div>
                    <div
                      class="col-md-12 row"
                      v-for="(subElment, index) in element.widthAndHeight"
                      :key="index"
                    >
                      <div class="col-lg-6 col-md-6">
                        <base-input
                          alternative=""
                          label="largeur max"
                          placeholder="largeur max"
                          input-classes="form-control-alternative"
                          v-model="subElment.widthMax"
                        />
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <base-input
                          alternative=""
                          label="largeur min"
                          placeholder="largeur min"
                          input-classes="form-control-alternative"
                          v-model="subElment.widthMin"
                        />
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <base-input
                          alternative=""
                          label="longeur max"
                          placeholder="longeur max"
                          input-classes="form-control-alternative"
                          v-model="subElment.heightMax"
                        />
                      </div>

                      <div class="col-lg-6 col-md-6">
                        <base-input
                          alternative=""
                          label="longeur min"
                          placeholder="longeur min"
                          input-classes="form-control-alternative"
                          v-model="subElment.heightMin"
                        />
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <base-input
                          alternative=""
                          label="Quantité"
                          placeholder="Quantité"
                          input-classes="form-control-alternative"
                          v-model="subElment.quantite"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="typeModel == 5">
                  <div class="col-lg-12 mt-3">
                    <base-input
                      alternative=""
                      label="type de l'article"
                      placeholder="type de l'article"
                      input-classes="form-control-alternative"
                      v-model="modelUpdate.typeArticle"
                      disabled
                    />
                  </div>

                  <div class="col-lg-12 mt-3">
                    <base-input
                      alternative=""
                      label="Prix"
                      placeholder="Prix"
                      input-classes="form-control-alternative"
                      v-model="modelUpdate.productPrice"
                      disabled
                    />
                  </div>

                  <div
                    class="row"
                    v-for="(element, index) in modelUpdate.vetements"
                    :key="index"
                  >
                    <hr style="background: #dc0746; width: 80%" />
                    <div class="col-md-12">
                      <label for="" class="form-control-label">image</label>
                    </div>
                    <div
                      class="col-lg-4 col-md-4 update-image-section text-center"
                      v-for="(image, index) in element.image"
                      :key="index"
                    >
                      <div class="updata-image">
                        <cld-context cloudName="dpy824jnw">
                          <cld-image :publicId="image" crop="scale" />
                        </cld-context>
                      </div>
                      <p
                        class="delete-imageToUpdate"
                        @click="deleteImageToUpdate(element.image, image)"
                      >
                        Supprimer l'image
                      </p>
                    </div>
                    <div class="col-md-12">
                      <base-button
                        href="#!"
                        class="btn btn-primary btn-color"
                        @click="openUploadModal(element.image)"
                        >Image</base-button
                      >
                    </div>

                    <div class="col-md-12">
                      <label for="" class="form-control-label">couleur</label>
                    </div>

                    <div
                      class="col-lg-4 col-md-4 update-image-section text-center"
                    >
                      <div class="updata-image">
                        <cld-context cloudName="dpy824jnw">
                          <cld-image :publicId="element.couleur" crop="scale" />
                        </cld-context>
                      </div>
                      <!-- <p
                    class="delete-imageToUpdate"
                    @click="deleteColorToUpdate(image)"
                  >
                    Supprimer l'image
                  </p> -->
                      <base-button
                        href="#!"
                        class="btn btn-primary btn-color"
                        @click="openUploadModalColor(element)"
                        >couleur</base-button
                      >
                    </div>
                    <div
                      class="col-md-12 row"
                      v-for="(subElment, index) in element.tailleEtQuantites"
                      :key="index"
                    >
                      <div class="col-lg-6 col-md-6">
                        <base-input
                          alternative=""
                          label="taille"
                          placeholder="taille"
                          input-classes="form-control-alternative"
                          v-model="subElment.taille"
                        />
                      </div>
                      <div class="col-lg-6 col-md-6">
                        <base-input
                          alternative=""
                          label="Quantité"
                          placeholder="Quantité"
                          input-classes="form-control-alternative"
                          v-model="subElment.quantite"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="col-lg-12">
                      <label for="" class="form-control-label"
                        >Date de disponibilité (début)</label
                      >
                      <input
                      v-model="modelUpdate.disponibility[0]"
                        type="date"
                        name=""
                        id=""
                        class="form-control form-control-alternative"
                      />
                    </div>
                    <div class="col-lg-12">
                      <label for="" class="form-control-label"
                        >Date de disponibilité (fin)</label
                      >
                      <input
                        v-model="modelUpdate.disponibility[1]"
                        type="date"
                        name=""
                        id=""
                        class="form-control form-control-alternative"
                      />
                    </div> -->

            <div class="col-lg-12 text-right btn-table-blog">
              <base-button type="" size="sm" @click="updateProduct()"
                >Modifier</base-button
              >
            </div>
          </modal>
          <modal :show.sync="modals.modal1">
            <!-- <img alt="Image placeholder modal-img" :src="row.imgLink" /> -->
            <cld-context cloudName="dpy824jnw">
              <div style="display: flex; justify-content: center">
                <cld-image :publicId="model.image" crop="scale" />
              </div>
            </cld-context>
          </modal>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <ul class="pagination">
        <li class="page-item prev-page" @click="prevPage">
          <a aria-label="Previous" class="page-link">
            <span aria-hidden="true"
              ><i aria-hidden="true" class="fa fa-angle-left"></i></span
          ></a>
        </li>
        <li class="page-item next-page" @click="nextPage">
          <a aria-label="Next" class="page-link"
            ><span aria-hidden="true"
              ><i aria-hidden="true" class="fa fa-angle-right"></i></span
          ></a>
        </li>
        <li></li>
      </ul>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { CldContext, CldImage } from "cloudinary-vue";
import { VueEditor, Quill } from "vue2-editor";
import Multiselect from "vue-multiselect";

export default {
  name: "projects-table",
  components: {
    CldContext,
    CldImage,
    VueEditor,
    Multiselect,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      typeModel: 0,
      urlImage:
        "https://res.cloudinary.com/dpy824jnw/image/upload/v1603987633/",
      url: "",
      publicId: "",
      modals: {
        modal1: false,
        modal2: false,
        modal3: false,
      },
      pageSize: 5,
      currentPage: 1,
      modelUpdate: {},
      //   label: "",
      //   category: [{ name: "Categorie", code: "0" }],
      //   description: "",
      //   quantity: "",
      //   pictures: [],
      //   productPrice:0,
      //   filtre:[{ name: "Filter", code: "0" }],
      //   motCle:[{ name: "Mot clé", code: "0" }],
      //   dimension:{
      //     width:[],
      //     height:[]
      //   },
      //   colors:[],
      //   taille:[{ name: "taille", code: "0" }],
      //   pointure:[{ name: "Pointure", code: "0" }],
      //   disponibility:[],
      //   type:""
      // },
      model: {
        FilterAll: "",
        FilterCategorie: "",
        label: "",
        category: "",
        description: "",
        quantity: "",
        pictures: [],
        dimension: {
          width: [],
          height: [],
        },
        labelUpdate: "",
        categoryUpdate: "",
        descriptionUpdate: "",
        quantityUpdate: "",
        idUpdate: "",
      },
      selectedFilters: [],
      tableData: [],
      dataToFilter: [],
      sortedData: [],
      options: [],
    };
  },
  created() {
    axios
      .get("https://giftstore-blog.herokuapp.com/api/product/get/all")
      .then((response) => {
        console.log("articles", response);
        this.tableData = response.data.fetchedArticles;
        this.dataToFilter = this.tableData;
        this.sortedElement();
        this.sortedData.forEach((item) => {
          item.formattedCategory = item.category[0];
          for (let index = 1; index < item.category.length; index++) {
            item.formattedCategory += `<br>${item.category[index]}`;
          }
        });
      })
      .catch((error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },
  methods: {
    // openUploadModalColor() {
    //   window.cloudinary
    //     .openUploadWidget(
    //       { cloud_name: "dpy824jnw", upload_preset: "vlgevino" },
    //       (error, result) => {
    //         if (!error && result && result.event === "success") {
    //           console.log("Done uploading..: ", result);
    //           this.url = result.info.url;
    //           this.publicId = result.info.public_id;
    //           this.modelUpdate.colors.push(this.publicId);
    //           console.log(this.model.colors);
    //         }
    //       }
    //     )
    //     .open();
    // },
    // categoryToUpdate(category){
    //   const tag = {
    //     name: category,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    //   };
    //   return tag
    // },
    // addCategory(newTag) {
    //   const tag = {
    //     name: newTag,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    //   };
    //   this.options.push(tag);
    //   this.modelUpdate.category.push(tag);
    // },
    // addFiltre(newTag) {
    //   const tag = {
    //     name: newTag,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    //   };
    //   this.options.push(tag);
    //   this.modelUpdate.filtre.push(tag);
    // },
    // addMotCle(newTag) {
    //   const tag = {
    //     name: newTag,
    //     code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    //   };
    //   this.options.push(tag);
    //   this.modelUpdate.motCle.push(tag);
    // },
    deleteImageToUpdate(obj, img) {
      if (obj.includes(img)) {
        obj.splice(obj.indexOf(img), 1);
      }
    },
    openUploadModalColor(element) {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpy824jnw", upload_preset: "vlgevino" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info.public_id);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              element.couleur = this.publicId;
            }
          }
        )
        .open();
    },
    openUploadModal(element) {
      window.cloudinary
        .openUploadWidget(
          { cloud_name: "dpy824jnw", upload_preset: "vlgevino" },
          (error, result) => {
            if (!error && result && result.event === "success") {
              console.log("Done uploading..: ", result.info.public_id);
              this.url = result.info.url;
              this.publicId = result.info.public_id;
              element.push(this.publicId);
              console.log("test", this.model.pictures);
            }
          }
        )
        .open();
    },
    //    deleteColorToUpdate(obj,img) {
    //   if (obj.includes(img)) {
    //     obj.splice(
    //       obj.indexOf(img),
    //       1
    //     );
    //   }
    // },
    // dateProduct(arg){
    //   console.log("date", this.modelUpdate.disponibility[0])
    // },
    sortedElement() {
      this.sortedData = this.dataToFilter.filter((row, index) => {
        let start = (this.currentPage - 1) * this.pageSize;
        let end = this.currentPage * this.pageSize;
        if (index >= start && index < end) return row;
      });
    },

    filterDataArray(MethodNameFilter) {
      var dataFilterData = this.tableData;
      if (this.selectedFilters.includes(MethodNameFilter)) {
        this.selectedFilters.splice(
          this.selectedFilters.indexOf(MethodNameFilter),
          1
        );
      }
      this.selectedFilters.push(MethodNameFilter);
      console.log(this.selectedFilters);
      this.selectedFilters.forEach((element) => {
        if (element === "filterCategorie") {
          dataFilterData = this.filterCategorie(dataFilterData);
        }
        if (element === "filterAll") {
          dataFilterData = this.filterAll(dataFilterData);
        }
      });
      // this.dataToFilter = this.sortedData;
      this.sortedElement();
    },
    filterCategorie: function (dataFilterData) {
      console.log("test", dataFilterData);
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.model.FilterCategorie == "Tous") {
          return element;
        } else if (element.category == this.model.FilterCategorie) {
          return element;
        }
      });
      return this.dataToFilter;
    },
    filterAll: function (dataFilterData) {
      this.dataToFilter = dataFilterData.filter((element) => {
        if (this.model.FilterAll === "") {
          return element;
        } else {
          return this.model.FilterAll.toLowerCase()
            .split(" ")
            .every((v) => element.label.toLowerCase().includes(v));
        }
      });
      return this.dataToFilter;
    },

    showDataElement(product) {
      this.modelUpdate = product;
      console.log(this.modelUpdate);
      if (this.modelUpdate.typeArticle == "autre") {
        this.typeModel = 1;
      } else if (this.modelUpdate.typeArticle == "chaussure") {
        this.typeModel = 2;
      } else if (this.modelUpdate.typeArticle == "bijoux") {
        this.typeModel = 3;
      } else if (this.modelUpdate.typeArticle == "tapis") {
        this.typeModel = 4;
      } else if (this.modelUpdate.typeArticle == "vetements") {
        this.typeModel = 5;
      }
    },
    updateProduct: function () {
      // const article = {
      //   id: this.model.idUpdate,
      //   label: this.model.labelUpdate,
      //   category: this.model.categoryUpdate,
      //   description: this.model.descriptionUpdate,
      //   quantity: this.model.quantityUpdate,
      //   pictures: this.model.pictures,
      //   publishedBy: "5f89bdd27a8a5f7751b4188f",
      // };
      console.log("this.modelUpdate");
      console.log(this.modelUpdate);
      axios
        .post("https://giftstore-blog.herokuapp.com/api/product/update", this.modelUpdate)
        .then((response) => {
          this.modals.modal3 = false;
          this.tableData = response.data.updatedProducts;
          this.dataToFilter = this.tableData;
          console.log("14584", this.dataToFilter);
          this.sortedElement();
          this.$notifications.notify({
            message: "Element bien modifier.,",
            type: "success",
          });
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.$notifications.notify({
            message: " Erreur lors de la modification.,",
            type: "danger",
          });
        });
    },
    deleteProduct: function (id_product) {
      var isokay = confirm("vous voulez vraiment supprimer cette élément");
      if (isokay) {
        const article = { productId: id_product };
        axios
          .post("https://giftstore-blog.herokuapp.com/api/product/delete", article)
          .then((response) => {
            console.log(response);
            this.tableData = response.data.updatedProducts;
            this.dataToFilter = this.tableData;
            this.sortedElement();
            this.$notifications.notify({
              message: "Element bien supprimer.",
              type: "success",
            });
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            this.$notifications.notify({
              message: "Erreur lors de la suppression.",
              type: "danger",
            });
          });
      }
    },
    nextPage: function () {
      if (this.currentPage * this.pageSize < this.tableData.length) {
        this.currentPage++;
      }
      this.sortedElement();
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--;
      this.sortedElement();
    },
    categorieProduct() {
      var tabCategory = [];
      console.log(this.modelUpdate.category);
      this.modelUpdate.category.forEach((element, index) => {
        tabCategory.push({ name: element, code: index });
      });
      this.modelUpdate.category = tabCategory;
      this.options = tabCategory;
      console.log(this.modelUpdate.category);
    },
  },
};
</script>
<style>
.btn-table-blog button {
  background: #dc0746 !important;
  color: #fff;
}
.btn-table-blog button:hover {
  background: #fff !important;
  color: #dc0746;
}
.modal-body img {
  width: 100% !important;
}
.Description-p {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-blog img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.btn-add-img button {
  background: #dc0746;
  color: #fff;
}
.btn-add-img button:hover {
  background: #fff;
  color: #dc0746;
}
.update-image-section {
  margin-bottom: 3%;
}
.updata-image {
  padding: 2%;
  height: 150px;
  overflow: hidden;
}
.updata-image img {
  height: 100%;
}
.delete-imageToUpdate {
  color: #dc0746;
  cursor: pointer;
}
</style>
